import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import './Playground.css';

const FIELD_MAP = {
  extract: {
    instructions:
      'You a top tier real estate agent.  You are very good at inferring what your client wants and needs. You output content in JSON format ONLY.',
    prompt: `
    You will be presented with a chain of conversations between a real estate agent and a potential client. Your task is to identify the detailed profiling
    and status of this client. Approach this task step-by-step, take your time, and do not skip steps: 
    1. Read the entire conversation chain. 
    2. Determine the customer's nuanced profile
    3. Extract a JSON with keys as follows: 
    4. Include as much evidence as possible, even if an output has already been stated.
    
    This is a JSON template showing the fields and types that should be extracted
    {
        "user_profile":{                    
            "already_purchased_home": {"value": "Boolean", "reason": "String"},
            "bio": "String | None",
            "children": {"value": "String | None", "reason": "String | None"},
            "occupation": {"value": "String | None", "reason": "String | None"},
            "age": {"value": "String | None", "reason": "String | None"},
            "motivation": {"value": "String | None", "reason": "String | None"},
            "location_motivation": {"value": "String | None", "reason": "String | None"},
            "interests": {"value": "String | None", "reason": "String | None"},
            "gender": {"value": "String | None", "reason": "String | None"},
            "marital_status": {"value": "String | None", "reason": "String | None"}
        },
        "user_budget": {
            "income": {"value": "String | None", "reason": "String | None"},
            "credit_score": {"value": "String | None", "reason": "String | None"},
            "down_payment": {"value": "Number | None", "reason": "String | None"},
            "preapproval": {"value": "Boolean", "reason": "String | None"},
        },
        "home_attributes": {
            "lower_budget": {"value": "int | None", "reason": "String | None"},
            "upper_budget": {"value": "int | None", "reason": "String | None"},
            "desired_bedrooms": {"value": "String | None", "reason": "String | None"},
            "desired_bathrooms": {"value": "String | None", "reason": "String | None"},
            "desired_sqft": {"value": "String | None", "reason": "String | None"},
            "desired_stories": {"value": "int | None", "reason": "String | None"},
            "location": {"value": "String | None", "reason": "String | None"}
        }
    }
    
    Sample Conversations
    
    Sample Conversation 1
    Agent: "Good morning, Alex! What brings you to the housing market today?"
    Client: "Hi, I'm looking for a spacious family home. My budget is around $350,000 to $500,000."
    Agent: "Great! Do you have a preferred location?"
    Client: "Somewhere close to downtown, but I also want a quiet neighborhood."
    
    Corresponding JSON Object for Sample 1
    {
    "user_profile": {                     
        "location_motivation": {"value":"space near downtown", "reason": "Would like a spacious family home  close to downtown."},
        "kids":{"value":"probably", "reason":"asked for a family home"},
        "already_purchased_home":{"value": False, "reason": "The client is actively shopping and does not say they have found or purchased a home"},
       "bio": "Alex is looking for a family home near downtown in the 350 to 400k range" 
    },
    "user_budget": {
        "lower_budget": {"value": 350000, "reason": "Alex mentioned a budget starting at $350,000."},
        "upper_budget": {"value": 500000, "reason": "Alex is willing to go up to $500,000 for the right home."}
    },
    "home_attributes"{
        "location": {"value": "Near downtown, quiet neighborhood", "reason": "Alex prefers a location close to downtown but in a quiet area."},                     
    }                        
    // Additional fields would be populated based on further conversation details
    }
    
    ### Sample Conversation 2
    Agent: "Hi Jordan, have you thought more about the type of house you're interested in?"
    Client: "Yes, I'm a single parent, so I need at least 4 bedrooms and a big backyard for my kids."
    Agent: "Understood. What's your budget looking like?"
    Client: "I'm hoping to stay under $450,000."
    
    ### Corresponding JSON Object for Sample 2
    {
        "user_profile": {                     
            "marital_status": {"value": "Single", "reason": "Jordan mentioned being a single parent."},
            "children": {"value": "2+", "reason": "Jordan mentioned having kids."},
            "location_motivation": {"value": "Big backyard for kids", "reason": "Jordan is looking for a house with a big backyard for their children."}
            "bio": "Jordan is looking for a 4 bedroom home with a big backyard for the kids" 
        },
        "user_budget": {
            "upper_budget": {"value": 450000, "reason": "Jordan wants to stay under $450,000."}
        },
        "home_attributes"{
            "desired_bedrooms": {"value": "4", "reason": "Jordan needs at least 4 bedrooms for their family."}
        }                       
        // Additional fields would be populated based on further conversation details
    }
    
    ### Sample Conversation 3
    Agent: "Morning Taylor! Are you still looking for that perfect starter home?"
    Client: "Yes, I'm a recent graduate and would love a 2-bedroom condo, preferably under $300,000."
    Agent: "Any specific areas you're interested in?"
    Client: "Ideally close to the city center, since I work there.  Plus I've got membership at a box there."
    
    ### Corresponding JSON Object for Sample 3
    {
        "user_profile": {                     
            "occupation": {"value": "Recent Graduate", "reason": "Taylor mentioned they are a recent graduate."},
            "interests":{"value": "fitness","reason":"Mentioned a membership at a box which is a CrossFit gym"}                     
        },
        "user_budget": {
            "upper_budget": {"value": 300000, "reason": "Taylor is looking for a condo under $300,000."}
        },
        "home_attributes"{
            "desired_bedrooms": {"value": 2, "reason": "Taylor is interested in a 2-bedroom condo."},
            "location": {"value": "City center", "reason": "Taylor prefers to live close to where they work in the city center."}
        }    
        // Additional fields would be populated based on further conversation details
    }
    
    Use the following communications to build a JSON object unique to this conversation chain.
 
    `,
    fields: [
      'id',
      'profile_version',
      'user_profile',
      'user_budget',
      'home_attributes',
    ],
  },
  outreach: {
    instructions:
      'You a top tier real estate agent who has been able to engage with clients through to clients where others have failed.  You output content in JSON format ONLY.',
    prompt: `
    Generate 3 creative and personalized ideas for reengaging with the client.
                     Then draft a message or a set of talking points for each of those engagement ideas.
                     
                     your response should be in the following format with content aligned to this example
                     {
                        "ideas": [
                            {
                                "idea": "A coloring book of treehouse for the clients daughter.",
                                "draft_content_subject": "A book for your daughter",
                                "draft_content": "Template for GPT-4 to generate treehouse coloring book items "Create an uncolored treehouse sitting in a backyard of a home.  The treehouse is made of wood and has a ladder leading up to it. The treehouse has a swing hanging from a tree branch.  The treehouse has a slide leading down to the ground.  The treehouse has a sandbox with a bucket and shovel.  The treehouse has a tire swing hanging from a tree branch.",
                                "idea_type": "creative"
                            },
                            {
                                "idea": "A personalized video introducing yourself to the client texted to them.",
                                "draft_content_subject": "Hi I'm John Doe and we are going to find your dream home",
                                "draft_content": "Video instructions: Dress business casual, Speak directly into your camera, Introduce yourself, Explain who you are and include a personal detail (like why you got into real estate), Explain why you are reaching out to them, Explain why you are the best agent to help them, Explain what you would like them to do next, Sign off with your name and contact information",
                                "idea_type": "personalized"
                            },
                            {
                                "idea": "A personal email about a venue in one of the locations they are interested in",
                                "draft_content_subject": "Another potential winner for you to check out",
                                "draft_content": " Dear Suzy\n I thought that the home on 354 main st was a great find.  I can really see why the backyard would be a great fit for your young children to run around in.  I also noticed that there is a great park nearby that has a playground and a pond.  I think you would really enjoy it.  I would love to show you the home and the park.  Please let me know if you are interested.\n\n Thanks,\n\n John Doe\n 555-555-5555\n",
                                "idea_type": "personalized"
                            }
                        ]
                    }
                    
    Use the following communications with the customer to make your recommendations 
    `,
    fields: ['id', 'outreach_ideas', 'outreach_version'],
  },
  funnel: {
    instructions:
      'You a top tier real estate agent who has been able to segment users so they can be nurtured through the sales pipeline.  You output content in JSON format ONLY.',
    prompt: `
Segment this user into the following buckets based on their previous actions.

finances
1. unknown. Not enough info to make a determination
2. Unknown financial resources. Interested but does not yet understand the finances.
3. financially_unqualified_due_to_income: Interested but does not have the financial resources to purchase a home.
4. financially_unqualified_due_to_credit: Interested but does not have the financial resources to purchase a home due to credit.
5. financially_unqualified_due_to_down_payment: Interested but does not have the financial resources to purchase a home due to down payment.
6. financially_unqualified_due_to_preapproval: Interested but does not yet have pre approval.
7. financially_qualified: Interested and has the financial resources to purchase a home.

property_preferences
1. unknown: Not enough info to make a determination
2. exploring: Interested but does not yet understand the property preferences.
3. specific_needs_not_met: The lead has specific property requirements (e.g., number of bedrooms, location) that haven't been met by current listings.
4. specific_needs_partially_met: Some, but not all, of the lead's property requirements are met by available listings.
5. specific_needs_met: The lead's property requirements are met by available listings.

timeline
1. no_specified_timeframe: The lead hasn't indicated when they intend to purchase.
2. long_term_prospect: The lead is considering a purchase in the distant future (e.g., more than a year).
3. medium_term_prospect: The lead is considering a purchase in the medium term (e.g., 6-12 months).
4. short_term_prospect: The lead is considering a purchase in the short term (e.g., < 6 months).
5. immediate_prospect: The lead is actively seeking to purchase as soon as possible.

engagement
1. passive: The lead occasionally interacts with listings or communications but is not actively seeking properties.
2. actively_researching: The lead regularly interacts with listings and may request additional information.
3. highly_engaged: The lead frequently interacts, asks questions, and shows high interest in specific properties.
4. ready_to_act: The lead is requesting viewings, seeking more detailed information, and is ready to take the next steps.

market_knowledge
1. unknown. Not enough info to make a determination
2. novice: The lead has limited or no knowledge of the real estate market.
3. learning: The lead is actively learning about the real estate market.
4. informed: The lead has a good understanding of the real estate market but may need guidance on specific topics.
5. experienced: The lead is very knowledgeable and understands market trends and pricing.

{
   "finances": {"value":"unknown" , "reason" : "No conversation with the shopper covering finances was seen"},
   "property_preferences": {"value":"specific_needs_partially_met", "reason" : "The shopper has a specific need for a 3 bedroom home. They have seen 2 homes that meet this criteria but they are not in the right location."},
   "timeline": {"value":"short_term_prospect", "reason" : "The shopper is looking to purchase a home before school starts in the fall."},
   "engagement": {"value":"highly_engaged", "reason" : "The shopper has been actively looking at homes and has requested a showing on 2 homes."},
   "market_knowledge": {"value":"learning", "reason" : "The shopper has been actively looking at homes and has requested a showing on 2 homes."}
}
Use the following communications with the customer to make your recommendations 
`,
    fields: ['id'],
  },
};

const Playground = ({ apiUrl }) => {
  const [leadContent, setLeadContent] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [roleInstructions, setRoleInstructions] = useState('');
  const [communications, setCommunications] = useState('');
  const [originalOutput, setOriginalOutput] = useState('');
  const [results, setResults] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { leadID } = useParams(); // Get the leadID from the URL
  const { clientID } = useParams(); // Get the ClientName from the URL

  const query = useQuery();
  const field = query.get('field');
  const PROMPT_KEY = 'prompt_' + field;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    // Load the content for the specified leadId
    // Replace this with your actual data fetching logic
    const fetchLeadContent = async prompt => {
      try {
        const response = await fetch(
          `${apiUrl}/api/Lead/${clientID}/${leadID}`,
        );
        const leadData = await response.json();
        setLeadContent(leadData);
        if (!prompt) {
          setPrompt(FIELD_MAP[field].prompt);
        } else {
          setPrompt(prompt);
        }
        setRoleInstructions(FIELD_MAP[field].instructions);

        const commsResponse = await fetch(
          `${apiUrl}/api/Communications/${clientID}/${leadID}`,
        );
        const commsData = await commsResponse.json();

        setCommunications(commsData.consolidated_communications);
        const newData = Object.keys(leadData)
          .filter(key => FIELD_MAP[field].fields.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: leadData[key],
            };
          }, {});
        setOriginalOutput(JSON.stringify(newData, null, 2));
      } catch (error) {
        console.error('Error fetching lead content:', error);
      }
    };

    const storedInstructions = localStorage.getItem(PROMPT_KEY);
    fetchLeadContent(storedInstructions);
  }, [PROMPT_KEY, apiUrl, clientID, field, leadID]);

  const runEval = async () => {
    setIsLoading(true);
    // save the instructions to local storage so you don't lose your work.
    localStorage.setItem(PROMPT_KEY, prompt);
    try {
      const response = await fetch(`${apiUrl}/api/EvaluatePrompt/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          roleInstructions,
          prompt,
          communications,
        }),
      });
      const results = await response.json();

      console.log(JSON.stringify(results, null, 2));
      setResults(JSON.stringify(results, null, 2));
    } catch (error) {
      console.error('Error fetching lead content:', error);
      setResults(error);
    }
    setIsLoading(false);
  };

  const resetPrompt = () => {
    setPrompt(FIELD_MAP[field].prompt);
    localStorage.removeItem(PROMPT_KEY);
  };

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div>
      {leadContent ? (
        <div className="container">
          <textarea
            className="editor"
            id="prompt"
            onChange={e => {
              setRoleInstructions(e.target.value);
            }}
            value={roleInstructions}
          ></textarea>
          <textarea
            className="editor"
            id="instructions"
            onChange={e => {
              setPrompt(e.target.value);
            }}
            value={prompt}
          ></textarea>
          <textarea
            className="editor"
            id="context"
            onChange={e => {
              setCommunications(e.target.value);
            }}
            value={communications}
          ></textarea>

          <div id="results">
            {isLoading ? (
              <Spin size="large"></Spin>
            ) : (
              <textarea
                className="editor"
                id="results_area"
                readOnly
                value={results}
              ></textarea>
            )}
          </div>

          <textarea
            className="editor"
            id="original"
            readOnly
            value={originalOutput}
          ></textarea>

          <div className="button-container">
            <Button disabled={isLoading} onClick={runEval}>
              {isLoading ? 'Running...' : 'Run Eval'}
            </Button>
            <Button onClick={resetPrompt}>Reset Prompt</Button>
            <Button onClick={handleBackClick}>Back</Button>
          </div>
        </div>
      ) : (
        <p>Loading lead content...</p>
      )}
    </div>
  );
};

Playground.propTypes = {
  apiUrl: PropTypes.string.isRequired,
};

export default Playground;
