import { useState } from 'react';

interface EvalPromptArgs {
  apiUrl: string;
  roleInstructions: string;
  prompt: string;
  communications: string;
  messages?: { role: string; content: string }[];
  response_format?: string;
}

export const useEvalPrompt = (args: EvalPromptArgs) => {
  const [evalResponse, setEvalResponse] = useState<string | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const evalPrompt = async () => {
    try {
      setLoading(true);
      setError(undefined);
      const response = await fetch(`${args.apiUrl}/api/EvaluatePrompt/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          roleInstructions: args.roleInstructions,
          prompt: args.prompt,
          communications: args.communications,
          messages: args.messages ?? [],
          response_format: args.response_format ?? 'json_object',
        }),
      });
      if (!response.ok) {
        throw new Error(`Error evaluating prompt: ${await response.text()}`);
      }
      const results = await response.json();

      setEvalResponse(results.content);
    } catch (error) {
      console.error('Error evaluating prompt:', error);
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };
  return { evalResponse, loading, error, evalPrompt, setEvalResponse };
};
