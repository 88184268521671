import { Alert } from 'antd';

interface AlertBannerProps {
  error: string | undefined;
  setError?: (error: string | undefined) => void;
  type?: 'error' | 'warning' | 'info' | 'success';
}

export const AlertBanner = (props: AlertBannerProps) => {
  return props.error == undefined ? undefined : (
    <Alert
      banner
      closable
      description={props.error}
      message={props.type == 'error' ? 'Error' : undefined}
      onClose={() =>
        props.setError != null ? props.setError(undefined) : undefined
      }
      style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}
      type={props.type || 'error'}
    />
  );
};
