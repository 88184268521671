import { Carousel, Divider, notification, Spin, theme } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import CustomDots from './custom-dots';
import { NextArrow, PrevArrow } from './custom-arrows';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Task, TasksResponse } from '../../../types';
import { CheckCircleOutlined } from '@ant-design/icons';
import Logo from '../../../Logo';
import './styles.css';

import PushToSendTask from './push-to-send-task';

const SUCCESS_STATUS = 'success';
const ERROR_STATUS = 'error';
const EXPIRED = 'true';

const ACTIONS = {
  TEXT_SENT: 'text_sent',
  EMAIL_LINK: 'email_link',
  EDIT: 'edit',
};

export const PushToSendPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);
  const { clientId, agentId } = useParams<{
    clientId: string;
    agentId: string;
  }>();
  const searchParams = useSearchParams()[0];
  const showAll = searchParams.get('showAll') === '1';
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const carouselRef = useRef<CarouselRef>(null);
  const actionTask = searchParams.get('activeTask'); // Task to show when the page loads
  const action = searchParams.get('action'); // Action to perform when the page loads
  const status = searchParams.get('status'); // Status of the action
  const message = searchParams.get('message'); // Message from the action
  const expired = searchParams.get('expired');
  const [carouselLoaded, setCarouselLoaded] = useState<boolean>(false);

  const [api, contextHolder] = notification.useNotification();

  // # https://apiUrl{clientId}/{agentId}/pts?action=edit&activeTask={taskId}
  // # https://apiUrl/{clientId}/{agentId}/pts?action=text_sent&status=success&activeTask={taskId}
  // # https://apiUrl/{clientId}/{agentId}/pts?action=text_sent&status=error&message=ErrorMessage&activeTask={taskId}
  useEffect(() => {
    const showError = (description: string) => {
      api.error({
        message: 'Error',
        description,
        duration: 0,
      });
    };

    const showSuccess = (description: string) => {
      api.success({
        message: 'Success',
        description,
        duration: 10,
      });
    };

    // responsible for components to bubble up error message
    if (error) {
      showError(error);
    }

    // responsible for components to bubble up success message
    if (alertMessage) {
      showSuccess(alertMessage);
    }

    if (expired && expired === EXPIRED) {
      showError('The link has expired.');
    }

    switch (action) {
      case ACTIONS.TEXT_SENT: {
        const task = completedTasks.find(task => task.id === actionTask);
        if (status === SUCCESS_STATUS && task) {
          showSuccess(
            `Text sent to ${task.lead.first_name} ${task.lead.last_name}.`,
          );
        } else if (status === ERROR_STATUS) {
          showError(message || 'Failed to send text.');
        }
        break;
      }
      case ACTIONS.EMAIL_LINK: {
        if (status === ERROR_STATUS) {
          showError(message || 'Failed to send email.');
        } else if (status === SUCCESS_STATUS) {
          showSuccess('Link sent to your email');
        }
        break;
      }
      case ACTIONS.EDIT: {
        const task = completedTasks.find(task => task.id === actionTask);
        if (task) {
          showError(
            `Unable to edit. Text for ${task.lead.first_name} ${task.lead.last_name} was already completed.`,
          );
        } else if (status === ERROR_STATUS) {
          showError(message || 'Failed to edit task.');
        }
        break;
      }
      default: {
        if (!action && status === ERROR_STATUS) {
          showError(
            message ||
              'Failed to perform action, please contact aiassistant@housewhisper.ai',
          );
        }
      }
    }

    return () => {
      setError(undefined);
      setAlertMessage(undefined);
    };
  }, [error, alertMessage, status, message, api, action, tasks, actionTask]);

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/Tasks/${clientId}/${agentId}?show_all=${showAll ? 1 : 0}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        const data = await response.json();
        const TasksResponse = { data: data as TasksResponse };
        const fetchedTasks = TasksResponse.data.tasks
          .filter(task => task.task_type === 'call_follow_up')
          .sort((a, b) => a.created_at.localeCompare(b.created_at));

        // Need separate vars because the react hooks are too slow to update the state
        const completedTasks = fetchedTasks.filter(task => task.checked_off);
        const activeTasks = fetchedTasks.filter(task => !task.checked_off);
        setTasks(activeTasks);
        setCompletedTasks(completedTasks);

        console.log('fetchedTasks:', fetchedTasks);
        console.log('actionTask:', actionTask);
        console.log('Active Tasks:', activeTasks);

        if (actionTask) {
          const taskIndex = activeTasks.findIndex(
            task => task.id === actionTask,
          );

          console.log('Inside actionTask:', actionTask);
          if (taskIndex > -1) {
            const reorderedTasks = [
              activeTasks[taskIndex],
              ...activeTasks.slice(0, taskIndex),
              ...activeTasks.slice(taskIndex + 1),
            ];
            setTasks(reorderedTasks);
            setIsEditing(action === 'edit');
            // Indicate that the carousel should navigate to a specific slide to get actionTask to work
            // This possibly isn't necessary if we keep the reordering logic above but if we want
            // to keep the original order of tasks we can use this and setCurrentSlide(taskIndex) to have it load the correct task
            // but keep the original order of tasks (aka load task 3 of 6)
            setCarouselLoaded(true);
            console.log('tasks:', reorderedTasks);
          }
        }
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
        setError('Failed to fetch tasks.');
      } finally {
        setLoading(false);
      }
    };

    loadTasks().catch(error =>
      console.error('Failed to execute loadTasks:', error),
    );
  }, [apiUrl, clientId, agentId, showAll, actionTask, action]);

  useEffect(() => {
    if (carouselLoaded && carouselRef.current) {
      carouselRef.current.goTo(currentSlide);
    }
  }, [carouselLoaded, currentSlide]);

  const handlePrev = () => {
    if (carouselRef.current) {
      const newIndex = (currentSlide - 1 + tasks.length) % tasks.length;
      carouselRef.current.goTo(newIndex);
      setCurrentSlide(newIndex);
      setIsEditing(true);
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      const newIndex = (currentSlide + 1) % tasks.length;
      carouselRef.current.goTo(newIndex);
      setCurrentSlide(newIndex);
      setIsEditing(true);
    }
  };

  const handleTaskRemove = () => {
    const updatedTasks = tasks.filter((_, index) => index !== currentSlide);
    setTasks(updatedTasks);
    if (updatedTasks.length > 0) {
      setCurrentSlide(currentSlide % updatedTasks.length);
      setIsEditing(true);
    } else {
      setCurrentSlide(0);
      setIsEditing(false);
    }
  };

  const { token } = theme.useToken();

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <span>
        {contextHolder}
        <div style={{ marginTop: 20 }}>
          <Logo />
          <Divider style={{ margin: '0' }} />
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: 50 }}>
              <Spin size="large" tip="Loading">
                <div className="ant-spin-content" />
              </Spin>
            </div>
          ) : tasks.length > 0 ? (
            <>
              <div className="carousel-controls">
                <PrevArrow onClick={handlePrev} />
                <CustomDots current={currentSlide} total={tasks.length} />
                <NextArrow onClick={handleNext} />
              </div>
              <Divider style={{ margin: '0' }} />
              <Carousel
                arrows={false}
                beforeChange={(from, to) => {
                  setCurrentSlide(to);
                  setIsEditing(true);
                }}
                dots={false}
                infinite={false}
                ref={carouselRef}
              >
                {tasks.map((task, index) => (
                  <PushToSendTask
                    agentId={agentId}
                    apiUrl={apiUrl}
                    isActive={index === currentSlide}
                    isEditing={index === currentSlide && isEditing}
                    key={task.id}
                    onTaskRemove={handleTaskRemove}
                    setAlertMessage={setAlertMessage}
                    setError={setError}
                    setLoading={setLoading}
                    task={task}
                    token={token}
                  />
                ))}
              </Carousel>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <CheckCircleOutlined
                style={{ fontSize: '80px', color: '#28638A' }}
              />
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '50px',
                  color: '#28638A',
                }}
              >
                You have followed up with everyone!
              </div>
            </div>
          )}
        </div>
      </span>
    </div>
  );
};
