import React from 'react';
import { Flex, Typography } from 'antd';

const { Text } = Typography;

interface PushToSendLeadBioProps {
  shortBio: string;
}

const PushToSendLeadBio: React.FC<PushToSendLeadBioProps> = ({ shortBio }) => {
  return (
    <div
      style={{
        maxWidth: '100%',
        margin: '20px 0',
        padding: '10px 20px',
        // paddingTop: '20px',
        background: '#F1F4F9',
        // borderRadius: token.borderRadiusLG,
        border: 'none',
        textAlign: 'left',
      }}
    >
      <Flex flex={1} gap="small" style={{ maxWidth: '100%' }}>
        <Flex
          align="center"
          flex={1}
          gap="small"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <Text
            style={{
              fontFamily: 'Roboto Slab',
              fontSize: '16px',
              color: '#28638A',
              fontWeight: 'bold',
            }}
          >
            Profile
          </Text>
        </Flex>
      </Flex>
      <div style={{ maxWidth: '100%', margin: '10px 0' }}>
        <Text
          style={{
            fontFamily: 'Roboto Slab',
            fontSize: '14px',
            color: '#28638A',
          }}
        >
          {shortBio}
        </Text>
      </div>
    </div>
  );
};

export default PushToSendLeadBio;
