import './ChatBubble.css';

interface ChatBubbleProps {
  message: string;
  isOwnMessage: boolean;
  timestamp: string;
  status: string;
}

const ChatBubble = ({
  message,
  isOwnMessage,
  timestamp,
  status,
}: ChatBubbleProps) => {
  return (
    <div
      className={`chat-bubble ${isOwnMessage ? 'own-message' : 'other-message'}`}
    >
      <div>{message}</div>
      <div className="chat-timestamp">{timestamp.split('+')[0]}</div>
      <div className="chat-timestamp">{status}</div>
    </div>
  );
};

export default ChatBubble;
