import { Button, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Logo';
import brokenHouse from '../../assets/images/cartoon_broken_house_purple.png';

import './styles.css';

export const ErrorPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate('/');
  };

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <div style={{ marginTop: 20 }}>
        <Logo />
        <Divider style={{ margin: '20px 0' }} />
        <div style={{ marginBottom: '20px' }}>
          <img
            alt="Cartoon Broken House"
            src={brokenHouse}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
        <h1 style={{ fontFamily: 'Roboto Slab', color: '#28638A' }}>Oops!</h1>
        <p
          style={{
            fontFamily: 'Roboto Slab',
            fontSize: '16px',
            color: '#28638A',
          }}
        >
          It looks like this house is a bit broken. Don't worry, we're on it!
        </p>
        <p
          style={{
            fontFamily: 'Roboto Slab',
            fontSize: '14px',
            color: '#28638A',
          }}
        >
          In the meantime, we can take you home.
        </p>
        <Button onClick={handleRetry} shape="round" type="primary">
          Take Me Home
        </Button>
      </div>
    </div>
  );
};
