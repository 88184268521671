import React from 'react';

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const PrevArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',
        paddingLeft: '10px',
        opacity: 1,
        visibility: 'visible',
        color: '#28638A',
        fontWeight: 'bold',
      }}
    >
      Previous
    </div>
  );
};

const NextArrow: React.FC<ArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        cursor: 'pointer',
        width: 'auto',
        height: 'auto',
        paddingRight: '10px',
        opacity: 1,
        visibility: 'visible',
        color: '#28638A',
        fontWeight: 'bold',
      }}
    >
      Next
    </div>
  );
};

export { PrevArrow, NextArrow };
