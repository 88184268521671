import { Typography } from 'antd';
import { ScheduledAppointment } from '../../types';
const { Text } = Typography;

interface ScheduledAppointmentProps {
  appointments: ScheduledAppointment[];
}

const SmallLineBreak = () => (
  <br style={{ lineHeight: 4, content: ' ', fontSize: '5pt' }} />
);

export const Appointments = ({
  appointments,
}: ScheduledAppointmentProps): JSX.Element => {
  return (
    <div
      style={{
        textAlign: 'left',
        background: 'white',
        fontFamily: 'Arial',
        fontSize: '11pt',
        padding: '10px',
      }}
    >
      <Text strong>Recommended Appointments</Text>
      <SmallLineBreak />
      {appointments.map((item, index) => (<div key={index}>
        {item?.has_location_and_time && item?.datetime && item?.has_location_and_time.toLowerCase() === 'yes'
          && <>
            <Text strong>Appointment: </Text>{item?.text}
            <SmallLineBreak />
            <Text strong>Location: </Text> {item?.location}
            <SmallLineBreak />
            <Text strong>Time: </Text> {item?.datetime}
          </>}
      </div>
      ))}
    </div>
  );
};
