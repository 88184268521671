import {
  Button,
  Col,
  Flex,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { AlertBanner } from '../../AlertBanner';
import { useSearchParams } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;

const DEFAULT_PROMPTS = ['recording', 'bio', 'partial_bio', 'agent_call'];

const ResultBox = ({ result }: { result: string | undefined }) => {
  return (
    <div
      style={{
        border: '1px solid black',
        width: '100%',
        backgroundColor: 'white',
        padding: 5,
        textAlign: 'left',
      }}
    >
      {result == null ? (
        <Spin size="large" />
      ) : (
        <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
          {JSON.stringify(result, null, 2)}
        </Paragraph>
      )}
    </div>
  );
};

const SaveModal = ({
  open,
  prompt,
  setOpen,
  savePrompt,
}: {
  open: boolean;
  prompt: string;
  setOpen: (visible: boolean) => void;
  savePrompt: (name: string) => Promise<void>;
}) => {
  const [name, setName] = useState<string>('');
  useEffect(() => {
    setName(prompt);
  }, [prompt]);
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        void savePrompt(name);
      }}
      open={open}
      title="Save Prompt"
    >
      <Text>Prompt Name</Text>
      <Input
        onChange={e => setName(e.target.value)}
        placeholder="Prompt name"
        value={name}
      />
    </Modal>
  );
};

const DataBox = ({
  data,
  title,
}: {
  data: Record<string, any> | string;
  title: string;
}) => {
  return (
    <div>
      <Title>{title}</Title>
      <div
        style={{
          maxHeight: 200,
          overflow: 'auto',
          border: '1px solid',
          backgroundColor: 'white',
          padding: 5,
        }}
      >
        <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
          {typeof data === 'string' ? data : JSON.stringify(data, null, 2)}
        </Paragraph>
      </div>
    </div>
  );
};

export const PlaygroundPage = ({ apiUrl }: { apiUrl: string }) => {
  // const { clientId } = useParams();
  // const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const [prompt, setPrompt] = useState<string>('');
  const [userPrompts, setUserPrompts] = useState<Record<string, any>[]>([]);
  const [loadingSavePrompt, setLoadingSavePrompt] = useState<boolean>(false);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const [systemPrompt, setSystemPrompt] = useState<string>('');
  const [instruction, setInstruction] = useState<string>('');
  const [data, setData] = useState<Record<string, any> | undefined>(undefined);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [model, setModel] = useState<string>('gpt');
  const [dataType, setDataType] = useState<string>('leadId');
  const [commDataType, setCommDataType] = useState<string>('all');
  const [fetchDataValue, setFetchDataValue] = useState<string>(
    searchParams.get('leadId') || '',
  );
  const [numCommunications, setNumCommunications] = useState<number | null>(
    null,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [nRuns, setNRuns] = useState<number>(3);
  const [results, setResults] = useState<(string | undefined)[]>([]);
  const [listingLink, setListingLink] = useState<string>('');
  const [parseListingLoading, setParseListingLoading] =
    useState<boolean>(false);
  const [listingData, setListingData] = useState<string | undefined>();
  const [leadData, setLeadData] = useState<Record<string, any> | undefined>(
    undefined,
  );
  const [responseFormat, setResponseFormat] = useState<string>('json_object');

  useEffect(() => {
    let data: Record<string, any> = {};
    if (leadData != null) {
      data = { ...leadData };
    }
    if (listingData != null) {
      data.listing = { data: listingData, link: listingLink };
    }
    if (Object.keys(data).length === 0) {
      setData(undefined);
    } else {
      setData(data);
    }
  }, [listingData, leadData]);

  const fetchPrompt = useCallback(async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/playground/prompt/${prompt}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error(await response.text());
      }
      const jsonData = await response.json();
      setSystemPrompt(jsonData.system);
      setInstruction(jsonData.instruction);
    } catch (err) {
      console.error('An error occurred while fetching the prompt:', err);
      setSystemPrompt('');
      setInstruction('');
      setError(`${err}`);
    }
  }, [prompt]);
  const fetchPrompts = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/playground/user-prompt`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }
      const jsonData = await response.json();
      setUserPrompts(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching the prompt:', err);
      setError(`${err}`);
    }
  };
  useEffect(() => {
    void fetchPrompts();
  }, []);

  const savePrompt = async (name: string) => {
    setLoadingSavePrompt(true);
    const clean_name = name.trim().toLowerCase().replace(/\s+/g, '-');
    if (DEFAULT_PROMPTS.includes(clean_name)) {
      setError('Cannot save default prompt, choose other name');
      return;
    }
    try {
      const response = await fetch(`${apiUrl}/api/playground/user-prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          system: systemPrompt,
          instruction,
          name: clean_name,
        }),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }
      await fetchPrompts();
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoadingSavePrompt(false);
      setOpenSaveModal(false);
    }
  };

  const fetchLeadData = async () => {
    setLoadingData(true);
    try {
      let value = fetchDataValue.replace(/\s+/g, '');
      let clientId = undefined;
      if (dataType === 'sourceLeadId') {
        if (value.includes('carolinaone')) {
          value = value.split('/').pop() || '';
          clientId = 2;
        } else if (value.includes('client14.sierrainteractivedev.com')) {
          const urlParams = new URLSearchParams(value.split('?')[1]);
          value = urlParams.get('id') || '';
          clientId = 1;
        } else if (value.includes('laughtonteam1')) {
          value = value.split('/').pop() || '';
          clientId = 4;
        }else if (value.includes('rockwell')) {
          value = value.split('/').pop() || '';
          clientId = 9;
        }
      }

      const response = await fetch(`${apiUrl}/api/playground/data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dataType,
          dataId: value,
          clientId,
          numCommunications: numCommunications,
          commDataType,
        }),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }
      const jsonData = await response.json();
      setLeadData(jsonData);
    } catch (err) {
      setError(`${err}`);
    } finally {
      setLoadingData(false);
    }
  };
  useEffect(() => {
    if (fetchDataValue) {
      void fetchLeadData();
    }
  }, []);
  const parseListingLink = async () => {
    setParseListingLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/listing/parse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: listingLink,
        }),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }
      const data = await response.text();
      setListingData(data);
    } catch (err) {
      setError(`${err}`);
    } finally {
      setParseListingLoading(false);
    }
  };
  const evaluatePrompt = async (
    i: number,
    system: string,
    instruction: string,
  ) => {
    try {
      const response = await fetch(`${apiUrl}/api/playground/evaluate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ system, instruction, model, responseFormat }),
      });
      if (!response.ok) {
        throw new Error(await response.text());
      }
      const jsonData = await response.json();
      setResults(prev => {
        const newResults = [...prev];
        newResults[i] = jsonData.result;
        return newResults;
      });
    } catch (err) {
      setResults(prev => {
        const newResults = [...prev];
        newResults[i] = `${err}`;
        return newResults;
      });
    }
  };
  const getMatches = (str: string) => {
    const matches = Array.from(str.matchAll(/<([^>]+)>/g))
      .map(match => ({
        text: match[1],
        index: match.index,
        len: match[1].length,
      }))
      .sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
    const replacedSystem: string[] = [];
    let cur = 0;
    matches.forEach(match => {
      const replace = match.text
        .split('.')
        .reduce((acc, key) => (acc != null ? acc[key] : acc), data);
      if (replace != null) {
        const val: string =
          typeof replace === 'string' ? replace : JSON.stringify(replace);
        replacedSystem.push(str.slice(cur, match.index));
        replacedSystem.push(val);
        cur = (match.index ?? 0) + match.len + 1;
      }
    });
    replacedSystem.push(str.slice(cur));
    return replacedSystem.length > 0 ? replacedSystem.join('') : str;
  };
  const runPrompts = async () => {
    setResults(Array(nRuns).fill(undefined));

    const promises: Promise<void>[] = [];
    for (let i = 0; i < nRuns; i++) {
      promises.push(
        evaluatePrompt(i, getMatches(systemPrompt), getMatches(instruction)),
      );
    }
  };
  //   useEffect(() => {
  //     void fetchData();
  //   }, [leadId]);

  return (
    <div>
      <AlertBanner error={error} setError={setError} />
      <Title level={1}>Playground</Title>
      <div>
        <Row gutter={5}>
          <Col md={5} sm={12}>
            <Flex align="center" gap={2}>
              <Title level={5} style={{ marginBottom: 0, marginRight: 5 }}>
                Model
              </Title>
              <Select
                defaultValue="gpt"
                onChange={(value: string) => setModel(value)}
                options={[
                  { value: 'gpt', label: 'GPT' },
                  { value: 'gpt-mini', label: 'GPT mini' },
                  { value: 'claude-haiku', label: 'Claude Haiku' },
                  { value: 'claude-sonnet', label: 'Claude Sonnet' },
                  { value: 'gemini-flash', label: 'Gemini Flash' },
                ]}
                style={{ width: '150px' }}
              />
              <Select
                defaultValue="json_object"
                onChange={(value: string) => setResponseFormat(value)}
                options={[
                  { value: 'json_object', label: 'JSON' },
                  { value: 'text', label: 'Text' },
                  // { value: 'voice', label: 'Voice' },
                ]}
                style={{ width: '150px' }}
              />
              <Title level={5} style={{ marginBottom: 0, marginRight: 5 }}>
                Number of runs
              </Title>
              <InputNumber onChange={e => setNRuns(e || 0)} value={nRuns} />
            </Flex>
          </Col>
          <Col md={12} sm={24}>
            <Flex align="flex-end" gap={5}>
              {/* <Text>Tell me what data you need</Text> */}
              {/* <TextArea></TextArea> */}
              <div style={{ flex: 3 }}>
                <Title
                  level={5}
                  style={{ marginBottom: 0, marginRight: 5, textAlign: 'left' }}
                >
                  Data Type
                </Title>
                <Flex gap={5}>
                  <Select
                    defaultValue="leadId"
                    onChange={(value: string) => setDataType(value)}
                    options={[
                      { value: 'leadId', label: 'Lead Id' },
                      { value: 'recordingId', label: 'Recording Id' },
                      { value: 'sourceLeadId', label: 'Source Lead Id' },
                      { value: 'agentCallId', label: 'Agent Call Id' },
                      { value: 'agentId', label: 'Agent Id' },
                    ]}
                    style={{ width: '150px' }}
                  />
                  <Input
                    onChange={e => setFetchDataValue(e.target.value)}
                    placeholder='Enter "leadId" or "recordingId" or "sourceLeadId"'
                    style={{ maxWidth: 300 }}
                    value={fetchDataValue}
                  ></Input>
                </Flex>
              </div>
              <div style={{ flex: 1 }}>
                <Text strong style={{ fontSize: 12, marginRight: 5 }}>
                  {' '}
                  Number of communications
                </Text>
                <InputNumber
                  onChange={e => setNumCommunications(e)}
                  value={numCommunications}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Text strong style={{ fontSize: 12, marginRight: 5 }}>
                  Comm Type
                </Text>
                <Select
                  defaultValue="all"
                  onChange={(value: string) => setCommDataType(value)}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'Exclude HW', label: 'exclude_HW' },
                    { value: 'text_message', label: 'SMS' },
                    { value: 'email', label: 'Email' },
                    { value: 'call', label: 'Call' },
                  ]}
                  style={{ width: '150px' }}
                />
              </div>
              <Button
                loading={loadingData}
                onClick={() => void fetchLeadData()}
                type="primary"
              >
                Load data
              </Button>
            </Flex>
          </Col>
          <Col md={7} sm={12}>
            <Title level={5} style={{ marginBottom: 0, marginRight: 5 }}>
              Prompt
            </Title>
            <Flex align="center" gap={5}>
              <Select
                defaultValue=""
                onChange={(value: string) => setPrompt(value)}
                options={[
                  {
                    label: <span>Production</span>,
                    title: 'prod',
                    options: DEFAULT_PROMPTS.map(prompt => ({
                      value: prompt,
                      label:
                        prompt.charAt(0).toUpperCase() +
                        prompt.slice(1).toLowerCase(),
                    })),
                  },
                  {
                    label: <span>User</span>,
                    title: 'user',
                    options: userPrompts.map(prompt => ({
                      value: prompt.name,
                      label: prompt.name,
                    })),
                  },
                ]}
                style={{ width: '300px' }}
              />
              <Button onClick={() => void fetchPrompt()} type="primary">
                Reset
              </Button>
              <Button
                onClick={() => void setOpenSaveModal(true)}
                type="primary"
              >
                Save
              </Button>
            </Flex>
          </Col>
          <Col md={6} sm={12}>
            <Title level={5}>Listing link</Title>
            <Flex align="center" gap={5}>
              <Input
                onChange={e => setListingLink(e.target.value)}
                placeholder="Enter listing url"
                style={{ maxWidth: 300 }}
                value={listingLink}
              ></Input>
              <Button
                loading={parseListingLoading}
                onClick={() => void parseListingLink()}
                type="primary"
              >
                Parse
              </Button>
            </Flex>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={10}>
            <Title level={2}>Data</Title>
            {data == null ? (
              <Paragraph>Please load data</Paragraph>
            ) : (
              <Space
                direction="vertical"
                size="middle"
                style={{ maxWidth: '100%', textAlign: 'left' }}
              >
                {Object.keys(data).map(key => (
                  <DataBox data={data[key]} key={key} title={key} />
                ))}
              </Space>
            )}
          </Col>
          <Col span={14}>
            <Title level={2}>System Prompt</Title>
            <TextArea
              onChange={e => setSystemPrompt(e.target.value)}
              rows={3}
              value={systemPrompt}
            />
            <Title level={2}>Instruction Prompt</Title>
            <TextArea
              onChange={e => setInstruction(e.target.value)}
              rows={10}
              value={instruction}
            />
            <Button onClick={() => void runPrompts()} type="primary">
              Evaluate
            </Button>
            <Row gutter={5}>
              {results.map((result, i) => (
                <Col key={`prompt-result-${i}`} span={8}>
                  <ResultBox result={result} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
      <SaveModal
        open={openSaveModal}
        prompt={prompt}
        savePrompt={savePrompt}
        setOpen={setOpenSaveModal}
      />
    </div>
  );
};
