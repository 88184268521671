import React, { Component } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

class CopyToClipboardButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false,
    };
  }

  copyToClipboard = () => {
    if (this.props.richTextToCopy) {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([this.props.textToCopy], {
          type: 'text/plain',
        }),
        'text/html': new Blob([this.props.richTextToCopy], {
          type: 'text/html',
        }),
      });
      navigator.clipboard.write([clipboardItem]);
    } else {
      navigator.clipboard.writeText(this.props.textToCopy);
    }
    const dataLayer = window.dataLayer || [];

    dataLayer.push({
      event: 'copy_to_clipboard',
      button_location: this.props.buttonLocation,
    });

    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 5000); // Reset isCopied after 5 seconds
  };

  render() {
    return (
      <Tooltip title="Copy to clipboard">
        <Button onClick={this.copyToClipboard} shape="circle">
          {this.state.isCopied ? 'Copied' : <CopyOutlined />}
        </Button>
      </Tooltip>
    );
  }
}

CopyToClipboardButton.propTypes = {
  richTextToCopy: PropTypes.string,
  textToCopy: PropTypes.string.isRequired,
  buttonLocation: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
