import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { SignInButton } from '../SignInButton';
import { canViewData } from './Auth';

const ClientSelect = () => {
  const navigator = useNavigate();
  const { accounts } = useMsal();

  const renderCustomer = (
    customerID: number,
    customerName: string,
  ): ReactNode => {
    if (canViewData(customerID)) {
      return (
        <Button
          onClick={() => navigator(`/leads/${customerID}?stage=hot`)} //add hot stage to set default and allow us to share links of empty stages
          type="primary"
        >
          {customerName}
        </Button>
      );
    }
    return <div></div>;
  };

  return (
    <div>
      <AuthenticatedTemplate>
        <h1>Select a Customer:</h1>

        {renderCustomer(1, 'Everydoor')}
        {renderCustomer(2, 'Carolina One')}
        {renderCustomer(3, 'Pacaso')}
        <p>
          Welcome
          {accounts[0] ? ' ' + accounts[0].name : 'Unknown'}
        </p>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5>
          <center>Please sign-in to see your information.</center>
          <SignInButton />
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default ClientSelect;
