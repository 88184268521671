import { Card, Modal, Segmented, Spin, Tag } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { formatDate } from './utils';

interface Communication {
  created_at: string;
  content_header: string;
  content: string;
  data_type: string;
  inbound: boolean;
}

interface CommunicationModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  communications?: Communication[] | null;
}

interface CommunicationModalWithDataProps extends CommunicationModalProps {
  leadId: string;
  apiUrl: string;
  clientId: string;
  batchId: string;
}

export const CommunicationModalWithData = ({
  isModalVisible,
  closeModal,
  leadId,
  apiUrl,
  clientId,
}: CommunicationModalWithDataProps) => {
  const [communications, setCommunications] = useState<Communication[] | null>(
    null,
  );
  const initialRender = useRef(true);
  useEffect(() => {
    const fetchComms = async () => {
      try {
        const communicationsResponse = await fetch(
          `${apiUrl}/api/Communications/${clientId}/${leadId}`,
        );
        const communicationsData = await communicationsResponse.json();
        setCommunications(communicationsData.communications);
      } catch (err) {
        console.error('An error occurred while fetching the lead:', err);
        setCommunications([]);
      }
    };

    if (isModalVisible && initialRender.current) {
      initialRender.current = false;
      fetchComms().then().catch(console.log);
    }
  }, [isModalVisible]);
  return (
    <CommunicationModal
      closeModal={closeModal}
      communications={communications}
      isModalVisible={isModalVisible}
    />
  );
};

export const CommunicationModal = ({
  isModalVisible,
  closeModal,
  communications,
}: CommunicationModalProps) => {
  const [selectedCommunication, setSelectedCommunication] = useState('All');
  const [selectedCommunicationDirection, setSelectedCommunicationDirection] =
    useState('Both');
  const comms = communications != null ? communications : [];

  const getLabelWithBadge = (type: string) => {
    const count = comms.filter(
      item => item.data_type === type.toLowerCase().replace(/ /g, '_'),
    ).length;
    return (
      <div>
        {type} <Tag>{count}</Tag>
      </div>
    );
  };

  return (
    <Modal
      onCancel={closeModal}
      onOk={closeModal}
      open={isModalVisible}
      title="Communications"
      width={1000}
    >
      <Segmented
        onChange={value => {
          if (typeof value === 'string') {
            setSelectedCommunication(value);
          }
        }}
        options={[
          {
            value: 'All',
            label: (
              <div>
                All<Tag>{comms.length}</Tag>
              </div>
            ),
          },
          {
            value: 'Call',
            label: getLabelWithBadge('Call'),
          },
          {
            value: 'Email',
            label: getLabelWithBadge('Email'),
          },
          {
            value: 'Text Message',
            label: getLabelWithBadge('Text Message'),
          },
          {
            value: 'Note',
            label: getLabelWithBadge('Note'),
          },
          {
            value: 'Task',
            label: getLabelWithBadge('Task'),
          },
          {
            value: 'Event',
            label: getLabelWithBadge('Event'),
          },
          {
            value: 'Appointment',
            label: getLabelWithBadge('Appointment'),
          },
        ]}
        value={selectedCommunication}
      />
      &nbsp;
      <Segmented
        onChange={value => {
          if (typeof value === 'string') {
            setSelectedCommunicationDirection(value);
          }
        }}
        options={[
          {
            value: 'Both',
            label: (
              <div>
                Both<Tag>{comms.length}</Tag>
              </div>
            ),
          },
          {
            value: 'Inbound',
            label: (
              <div>
                Inbound
                <Tag>{comms.filter(item => item.inbound === true).length}</Tag>
              </div>
            ),
          },
          {
            value: 'Outbound',
            label: (
              <div>
                Outbound
                <Tag>{comms.filter(item => item.inbound === false).length}</Tag>
              </div>
            ),
          },
        ]}
        value={selectedCommunicationDirection}
      />
      <Spin size="large" spinning={communications == null} tip="Loading">
        {comms
          .filter(
            item =>
              selectedCommunication === 'All' ||
              item.data_type ===
                selectedCommunication.toLowerCase().replace(/ /g, '_'),
          )
          .filter(
            item =>
              selectedCommunicationDirection === 'Both' ||
              (item.inbound === true &&
                selectedCommunicationDirection === 'Inbound') ||
              (item.inbound === false &&
                selectedCommunicationDirection === 'Outbound'),
          )
          .map((item, index) => (
            <Card
              key={index}
              title={
                <div>
                  {item.content_header.split(' ').slice(0, -2).join(' ')}
                  &nbsp;
                  {formatDate(item.created_at)}
                </div>
              }
            >
              <p>{item.content}</p>
            </Card>
          ))}
      </Spin>
    </Modal>
  );
};
