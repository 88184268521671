import { CheckCircleOutlined } from '@ant-design/icons';
import { Carousel, Divider, notification, Spin, theme } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Logo from '../../../Logo';
import { Task, TasksResponse } from '../../../types';
import { NextArrow, PrevArrow } from '../pushToSend/custom-arrows';
import CustomDots from '../pushToSend/custom-dots';
import '../pushToSend/styles.css';

import PushToSendTaskEmail from './push-to-send-task-email';

export const PushToSendEmailPage = ({
  apiUrl,
}: {
  apiUrl: string;
}): JSX.Element => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const { clientId, agentId } = useParams<{
    clientId: string;
    agentId: string;
  }>();
  const searchParams = useSearchParams()[0];
  const showAll = searchParams.get('showAll') === '1';
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const carouselRef = useRef<CarouselRef>(null);
  const activeTask = searchParams.get('activeTask');
  const action = activeTask != null ? searchParams.get('action') : null;
  const [carouselLoaded, setCarouselLoaded] = useState<boolean>(false);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (error) {
      api.error({
        message: 'Error',
        description: error,
        duration: 0,
      });
    }

    if (alertMessage) {
      api.success({
        message: 'Success',
        description: alertMessage,
      });
    }

    return () => {
      setError(undefined);
      setAlertMessage(undefined);
    };
  }, [error, alertMessage]);

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/Tasks/${clientId}/${agentId}?show_all=${showAll ? 1 : 0}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        const data = await response.json();
        const TasksResponse = { data: data as TasksResponse };
        const fetchedTasks = TasksResponse.data.tasks
          .filter(
            task =>
              task.task_type === 'call_follow_up_email' &&
              task.checked_off === false,
          )
          .sort((a, b) => a.created_at.localeCompare(b.created_at));
        setTasks(fetchedTasks);
        console.log('fetchedTasks:', fetchedTasks);
        if (activeTask) {
          const taskIndex = fetchedTasks.findIndex(
            task => task.id === activeTask,
          );

          console.log('activeTask:', fetchedTasks);
          if (taskIndex > -1) {
            const reorderedTasks = [
              fetchedTasks[taskIndex],
              ...fetchedTasks.slice(0, taskIndex),
              ...fetchedTasks.slice(taskIndex + 1),
            ];
            setTasks(reorderedTasks);
            setIsEditing(true);
            // Indicate that the carousel should navigate to a specific slide to get actionTask to work
            // This possibly isn't necessary if we keep the reordering logic above but if we want
            // to keep the original order of tasks we can use this and setCurrentSlide(taskIndex) to have it load the correct task
            // but keep the original order of tasks (aka load task 3 of 6)
            setCarouselLoaded(true);
            console.log('tasks:', reorderedTasks);
          }
        }
      } catch (error) {
        console.error('Failed to fetch tasks:', error);
        setError('Failed to fetch tasks.');
      } finally {
        setLoading(false);
      }
    };

    loadTasks().catch(error =>
      console.error('Failed to execute loadTasks:', error),
    );
  }, [apiUrl, clientId, agentId, showAll]);

  useEffect(() => {
    if (carouselLoaded && carouselRef.current) {
      carouselRef.current.goTo(currentSlide);
    }
  }, [carouselLoaded, currentSlide]);

  const handlePrev = () => {
    if (carouselRef.current) {
      const newIndex = (currentSlide - 1 + tasks.length) % tasks.length;
      carouselRef.current.goTo(newIndex);
      setCurrentSlide(newIndex);
      setIsEditing(true);
    }
  };

  const handleNext = () => {
    if (carouselRef.current) {
      const newIndex = (currentSlide + 1) % tasks.length;
      carouselRef.current.goTo(newIndex);
      setCurrentSlide(newIndex);
      setIsEditing(true);
    }
  };

  const handleTaskRemove = () => {
    const updatedTasks = tasks.filter((_, index) => index !== currentSlide);
    setTasks(updatedTasks);
    if (updatedTasks.length > 0) {
      setCurrentSlide(currentSlide % updatedTasks.length);
      setIsEditing(true);
    } else {
      setCurrentSlide(0);
      setIsEditing(false);
    }
  };

  const { token } = theme.useToken();

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <span>
        {contextHolder}
        <div style={{ marginTop: 20 }}>
          <Logo />
          <Divider style={{ margin: '0' }} />
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: 50 }}>
              <Spin size="large" tip="Loading">
                <div className="ant-spin-content" />
              </Spin>
            </div>
          ) : tasks.length > 0 ? (
            <>
              <div className="carousel-controls">
                <PrevArrow onClick={handlePrev} />
                <CustomDots current={currentSlide} total={tasks.length} />
                <NextArrow onClick={handleNext} />
              </div>
              <Divider style={{ margin: '0' }} />
              <Carousel
                arrows={false}
                beforeChange={(from, to) => {
                  setCurrentSlide(to);
                  setIsEditing(true);
                }}
                dots={false}
                infinite={false}
                ref={carouselRef}
              >
                {tasks.map((task, index) => (
                  <PushToSendTaskEmail
                    agentId={agentId}
                    apiUrl={apiUrl}
                    isActive={index === currentSlide}
                    isEditing={index === currentSlide && isEditing}
                    key={task.id}
                    onTaskRemove={handleTaskRemove}
                    setAlertMessage={setAlertMessage}
                    setError={setError}
                    setLoading={setLoading}
                    task={task}
                    token={token}
                  />
                ))}
              </Carousel>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <CheckCircleOutlined
                style={{ fontSize: '80px', color: '#28638A' }}
              />
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '50px',
                  color: '#28638A',
                }}
              >
                You have followed up with everyone!
              </div>
              <Link
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  fontSize: '16px',
                  textDecoration: 'underline',
                }}
                to={`/leads2/${clientId}/${agentId || ''}`}
              >
                Go to Tasks
              </Link>
            </div>
          )}
        </div>
      </span>
    </div>
  );
};
