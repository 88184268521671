import { Flex, theme, Typography } from 'antd';
import { Task } from '../../types';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import TaskIcon, { TaskIconProps } from './task-icon';
const { Text } = Typography;

const getAppointmentTitle = (task: Task) => {
  let title = `${task.title}`;
  if (task.due_date != null) {
    title += ` on ${formatDate(task.due_date)}`;
  }
  return title;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
};

interface TaskTitleProps extends TaskIconProps {}

const TaskTitlePart = (props: { task: Task }) => {
  const { task } = props;
  const isAppointment = task.task_type === 'appointment';
  const title = task?.dynamic_title ?? task.title;
  return (
    <>
      <div>
        <Text
          delete={task.checked_off}
          ellipsis={!isAppointment}
          style={{
            fontFamily: 'Roboto Slab',
            fontSize: '14px',
            color: '#28638A',
            textDecoration: task.rejected ? 'line-through' : 'none',
          }}
        >
          {title}
        </Text>
      </div>
      <div>
        <Text
          delete={task.checked_off}
          style={{
            fontFamily: 'Roboto',
            fontSize: '10px',
            color: '#28638A',
            textDecoration: task.rejected ? 'line-through' : 'none',
          }}
        >
          {task.due_date &&
            (task.task_type === 'appointment' ||
              task.task_type === 'missed_inbound') &&
            formatDate(task.due_date)}
        </Text>
      </div>
    </>
  );
};

export const TaskTitle = (props: { task: Task }) => {
  const { task } = props;
  return (
    <>
      <Flex justify="space-between" style={{ textAlign: 'left' }}>
        <div style={{ maxWidth: '100%' }}>
          <TaskTitlePart task={task} />
        </div>
      </Flex>
    </>
  );
};

export const SubTaskTitle = (props: TaskTitleProps) => {
  const { task } = props;
  return (
    <>
      <Flex justify="space-between" style={{ textAlign: 'left' }}>
        <div style={{ maxWidth: '80%' }}>
          <TaskTitlePart task={task} />
        </div>
        <div
          style={{
            marginRight: '5px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '2em',
            color: '#28638A',
          }}
        >
          <TaskIcon
            pushToSend={props.pushToSend}
            task={task}
            toggleTask={props.toggleTask}
          />
        </div>
      </Flex>
    </>
  );
};
