import React from 'react';
import { Task } from '../../../types';
import { Button, ConfigProvider, Flex, GlobalToken, Typography } from 'antd';
import { crmLink } from '../../../utils';
import { SelectOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface PushToSendLeadContentProps {
  task: Task;
  token: GlobalToken;
}

const PushToSendLeadContent: React.FC<PushToSendLeadContentProps> = ({
  task,
  token,
}) => {
  return (
    <div
      style={{
        marginBottom: 12,
        background: '#F1F4F9',
        borderRadius: token.borderRadiusLG,
        border: 'none',
        textAlign: 'left',
        padding: '5px',
      }}
    >
      <Flex flex={1} gap="small" style={{ maxWidth: '100%' }}>
        <Flex
          align="center"
          flex={1}
          gap="small"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <Text
            style={{
              fontFamily: 'Roboto Slab',
              fontSize: '16px',
              color: '#28638A',
              fontWeight: 'bold',
            }}
          >
            {task.lead.first_name} {task.lead.last_name}
          </Text>
          <a
            href={crmLink(task.lead)}
            onAuxClick={e => {
              e.stopPropagation();
            }}
            onClick={e => {
              e.stopPropagation();
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: '#28638A',
                  },
                },
              }}
            >
              <Button
                ghost
                icon={<SelectOutlined rotate={90} />}
                shape="round"
                style={{
                  fontFamily: 'Roboto Slab',
                  fontWeight: 400,
                  marginLeft: 'auto',
                  flexShrink: 0, // Ensure the button does not shrink
                }}
                type="primary"
              >
                CRM
              </Button>
            </ConfigProvider>
          </a>
        </Flex>
      </Flex>
    </div>
  );
};

export default PushToSendLeadContent;
