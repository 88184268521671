import React, { useEffect, useState } from 'react';
import { Button, Divider, Select, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Logo from '../../Logo';
const { Option } = Select;

interface Agent {
  id: string;
  first_name: string;
  last_name: string;
  phone: string;
}
const AGENTS = [
  {
    id: 'c50d3552-41db-4086-a93c-1b10b3b8a3ec',
    first_name: 'Bill',
    last_name: 'Hight',
    phone: '+16239804852',
  },
  {
    id: '28a55b55-408d-436d-9454-a250da21628d',
    first_name: 'Chrissy',
    last_name: 'Clemons',
    phone: '+14257803780',
  },
  {
    id: '99df55c6-02a0-4639-ba97-d5af39618890',
    first_name: 'Juli',
    last_name: 'Forsyth',
    phone: '+16025254103',
  },
  {
    id: '7fc421f8-fc99-4985-99ae-ed05375d2da8',
    first_name: 'Robert',
    last_name: 'Tate',
    phone: '+16232253776',
  },
  {
    id: '4425a141-9a80-4084-a478-9bb21d25d16d',
    first_name: 'Rolf',
    last_name: 'Skog',
    phone: '+12064768806',
  },
  {
    id: '5e5d62c1-8851-41e8-ac02-23c7a6fbda3a',
    first_name: 'Alex',
    last_name: 'Kutner',
    phone: '+1202292734',
  },
  {
    id: 'fe06856f-d4c9-4715-9dbe-09bb2a43bbd7',
    first_name: 'Liisa',
    last_name: 'Sewell',
    phone: '+18436936027',
  },
  {
    id: 'e180f493-47d4-4980-bdf2-57fe75584dba',
    first_name: 'Chris',
    last_name: 'Baxter',
    phone: '+18432762762',
  },
  {
    id: '4275f357-4a8e-4e76-bcdb-19dbf40975ee',
    first_name: 'Sheena',
    last_name: 'Maurer',
    phone: '+18438600178',
  },
];

export const RolfInternal = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const [selectedAgent, setSelectedAgent] = useState<Agent | undefined>(
    undefined,
  );
  const [sms, setSms] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleAgentChange = (value: string) => {
    setSelectedAgent(AGENTS.find(agent => agent.id === value));
  };

  const [agentData, setAgentData] = useState<Record<string, any> | undefined>(
    undefined,
  );

  const sendSms = async () => {
    if (!selectedAgent) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/calls/sms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agentId: selectedAgent.id,
          message: sms,
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to send SMS: ${response.statusText}`);
      }
      const jsonData = await response.json();
      console.log(jsonData);
    } catch (err) {
      console.error('An error occurred while sending the SMS:', err);
      window.alert(`An error occurred while sending the SMS ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchAgentData = async () => {
    if (!selectedAgent) {
      return;
    }
    setAgentData(undefined);
    try {
      const response = await fetch(`${apiUrl}/api/calls/agents`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: selectedAgent.phone,
          message: sms,
        }),
      });
      const jsonData = await response.json();
      setAgentData(jsonData);
      console.log(jsonData);
    } catch (err) {
      console.error('An error occurred while fetching the agents:', err);
    }
  };
  useEffect(() => {
    fetchAgentData().then().catch(console.log);
  }, [selectedAgent]);

  return (
    <div>
      <Logo />
      <Divider />
      <Space direction="vertical" style={{ width: '100%', marginTop: 10 }}>
        <Select
          defaultValue="Select an agent"
          onChange={handleAgentChange}
          style={{ width: 200 }}
          value={selectedAgent?.id}
        >
          {AGENTS.map(agent => (
            <Option key={agent.id} value={agent.id}>
              {`${agent.first_name} ${agent.last_name}`}
            </Option>
          ))}
        </Select>
        {selectedAgent && (
          <div
            style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
          >
            {agentData == null && <p>Loading...</p>}
            {agentData && agentData.appointment && (
              <div style={{ marginBottom: 5 }}>
                <p>Appointment: {agentData.appointment}</p>
                <p>
                  Lead: {agentData.lead.first_name} {agentData.lead.last_name}
                </p>
              </div>
            )}
            <TextArea
              onChange={event => setSms(event.target.value)}
              value={sms}
            />
            <Button
              loading={loading}
              onClick={() => void sendSms()}
              style={{
                marginTop: 5,
                background:
                  'linear-gradient(230.52deg, #16FEBF 0.37%, #338FF0 48.45%, #A328FF 93.73%)',
              }}
            >
              Send
            </Button>
          </div>
        )}
      </Space>
    </div>
  );
};
