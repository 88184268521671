import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useMsal } from '@azure/msal-react';

// User types
type UserType = {
  userID: string;
  name: string;
} | null;

// Create a context for the user with a default value
const UserContext = createContext<UserType>(null);

// Create a provider component
const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accounts } = useMsal();
  const [user, setUser] = useState<UserType>(null);

  useEffect(() => {
    console.log('accounts', accounts);
    if (accounts.length > 0) {
      // Extract needed user details from accounts
      setUser({
        userID: accounts[0].localAccountId,
        name: accounts[0].username,
      });
    }
  }, [accounts]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
export type { UserType };
