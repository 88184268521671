export const PrivacyStatement = ({
  apiUrl,
}: {
  apiUrl: string;
}): JSX.Element => {
  return (
    <div>
      <div>HouseWhisper AI Privacy Policy</div>
      No mobile information will be shared with third parties/affiliates for
      marketing/promotional purposes. All the above categories exclude text
      messaging originator opt-in data and consent; this information will not be
      shared with any third parties
    </div>
  );
};
