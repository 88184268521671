import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Divider, Input, Select, Typography } from 'antd';
import './styles.css';
import Logo from '../../../Logo';
import { useParams } from 'react-router-dom';
import SendListingsCarouselDisplay from './send-listings-carousel';

const { Title, Text } = Typography;
const { Option } = Select;

export const LeadsSendListings = ({
  apiUrl,
}: {
  apiUrl: string;
}): JSX.Element => {
  const [leads, setLeads] = useState<
    { id: number; first_name: string; last_name: string }[]
  >([]);
  const [selectedName, setSelectedName] = useState<string>('');
  const [listingUrl, setListingUrl] = useState(''); // State to hold the listing URL input
  const [carouselData, setCarouselData] = useState<any>(null);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { clientId, agentId } = useParams<{
    clientId: string;
    agentId: string;
  }>();

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get<
          { id: number; first_name: string; last_name: string }[]
        >(`${apiUrl}/api/leads/${clientId}/${agentId}`);
        console.log(response);
        setLeads(response.data || []);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, [apiUrl, clientId, agentId]);

  // Function to validate the URL format
  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  interface SmsData {
    listing: string;
    listingUrl: string;
    leadId: string;
  }

  const generateListingSms = async (data: SmsData) => {
    try {
      const response = await axios.post(`${apiUrl}/api/listing/sms`, data);
      console.log('Response:', response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data);
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  const parseListing = async (url: string) => {
    try {
      // api/listing/parse
      const response = await axios.post(`${apiUrl}/api/listing/parse`, { url });
      console.log('Parse Listing Response:', response.data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response?.data);
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  const handleGenerate = async () => {
    console.log('Generate:', selectedName, listingUrl);
    setLoading(true);

    const parsedListing = await parseListing(listingUrl);

    const smsData = {
      leadId: selectedName,
      listingUrl,
      listing: parsedListing,
    };

    console.log('SMS DATA: ', smsData);

    const generatedListings = await Promise.all([
      generateListingSms(smsData),
      generateListingSms(smsData),
      generateListingSms(smsData),
    ]);

    console.log('handleGenerate ', generatedListings);

    setCarouselData(generatedListings);
    setShowCarousel(true);
    setLoading(false);
  };

  const handleAddAdditionalListing = () => {
    console.log('Add additional listing');
    setSelectedName(''); // Resetting the selected name
    setListingUrl(''); // Resetting the listing URL
    setShowCarousel(false);
  };

  return (
    <div
      style={{
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Logo />
      <Divider />
      <Title
        level={3}
        style={{
          fontFamily: 'Roboto Slab',
          fontWeight: '500',
          textAlign: 'left',
          marginTop: 20,
        }}
      >
        Send Listings
      </Title>
      <div
        style={{
          backgroundColor: '#f0f0f0',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        <Text
          strong
          style={{ display: 'block', marginBottom: 10, textAlign: 'left' }}
        >
          Client
        </Text>
        <Select
          filterOption={(input, option) => {
            const label = option?.label?.toString().toLowerCase();
            return label ? label.includes(input.toLowerCase()) : false;
          }}
          onChange={value => setSelectedName(value)}
          placeholder="Select a name"
          showSearch
          style={{ width: '100%', marginBottom: 20 }}
        >
          {leads.map(lead => (
            <Option key={lead.id} label={lead.first_name} value={lead.id}>
              {lead.first_name + ' ' + lead.last_name}
            </Option>
          ))}
        </Select>
        <Text
          strong
          style={{ display: 'block', marginBottom: 10, textAlign: 'left' }}
        >
          Listing URL
        </Text>
        <Input
          onChange={e => setListingUrl(e.target.value)}
          placeholder="Enter listing URL"
          style={{ width: '100%', padding: '8px', marginBottom: 10 }}
          type="text"
          value={listingUrl}
        />
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}
        >
          {/* <Button onClick={handleAddAdditionalListing} style={{ marginTop: 10 }}>Add another listing</Button> */}
          <Button
            disabled={!isValidUrl(listingUrl) || !selectedName}
            loading={loading}
            onClick={handleGenerate}
            style={{ marginTop: 10 }}
            type="primary"
          >
            Generate
          </Button>
        </div>
      </div>
      <div>
        {showCarousel && carouselData && (
          <div style={{ marginTop: 20 }}>
            <SendListingsCarouselDisplay messages={carouselData} />
          </div>
        )}
      </div>
    </div>
  );
};
