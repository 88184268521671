import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Descriptions,
  Dropdown,
  Radio,
  Slider,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DownOutlined, ReadOutlined, SendOutlined } from '@ant-design/icons';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import './LeadList.css';
import { ExpandedLead } from './ExpandedLead';
import PropTypes from 'prop-types';
import { isAdmin } from '../Insights/Auth';

function LeadList({ apiUrl }) {
  const { clientID } = useParams(); // Get the ClientName from the URL
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const shouldFetch = useRef(true);
  const shouldFetchBatches = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const segmentsParam = searchParams.get('segments');
  const [localStartDate, setLocalStartDate] = useState(
    searchParams.get('startDate') || 0,
  );
  const [localEndDate, setLocalEndDate] = useState(
    searchParams.get('endDate') || 100,
  );
  let segments = [];
  if (segmentsParam) {
    segments = segmentsParam.split(',');
  }
  const [tableState, setTableState] = useState({
    current: searchParams.get('current') || 1,
    pageSize: searchParams.get('pageSize') || 20,
    stage: searchParams.get('stage') || [],
    field: searchParams.get('field') || 'lastCommunication',
    order: searchParams.get('order') || 'ascend',
    segments: segments,
    startDate: searchParams.get('startDate') || 0,
    endDate: searchParams.get('endDate') || 100,
  });
  const [selectedBatchID, setSelectedBatchID] = useState(
    searchParams.get('batchID') || null,
  );
  const [filter, setFilter] = useState(searchParams.get('filter') || 'focus');
  const { Title, Text } = Typography;
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false);
  const crmLink = lead => {
    if (clientID === '1') {
      return `https://client14.sierrainteractivedev.com/lead-detail.aspx?id=${lead.source_id}`;
    } else if (clientID === '2') {
      return `https://carolinaone2.followupboss.com/2/people/view/${lead.source_id}`;
    }
  };

  // Roll up the fields that we want to store as search params into a single object
  useEffect(() => {
    const newSearchParams = {
      ...tableState,
      segments: tableState.segments && tableState.segments.join(','),
    };

    if (selectedBatchID) {
      newSearchParams.batchID = selectedBatchID;
    }

    if (newSearchParams.segments === null || newSearchParams.segments == []) {
      delete newSearchParams.segments;
    }

    // Remove empty stage search fields to keep a cleaner URL and not handle null string on server.
    if (newSearchParams.stage === null) {
      delete newSearchParams.stage;
    }

    setSearchParams(newSearchParams);
  }, [selectedBatchID, setSearchParams, tableState]);

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        shouldFetchBatches.current = false;
        const response = await fetch(`${apiUrl}/api/Batches/${clientID}`);
        const data = await response.json();
        const batchesWithLabels = data.batches.map((batch, index) => {
          return {
            ...batch,
            label:
              (index === 0 ? 'Latest (' : '') +
              moment(batch.created_at).format('MM/DD') +
              (index === 0 ? ')' : ''),
          };
        });

        setBatches(batchesWithLabels);
      } catch (err) {
        console.error('An error occurred while fetching batches:', err);
      }
    };
    if (shouldFetchBatches.current) {
      fetchBatches();
    }
  }, [apiUrl, clientID]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${apiUrl}/api/Leads/${clientID}/?page=${searchParams.get(
            'current',
          )}&pageSize=${searchParams.get(
            'pageSize',
          )}&sortField=${searchParams.get(
            'field',
          )}&sortOrder=${searchParams.get('order')}&filter=${searchParams.get(
            'filter',
          )}${
            searchParams.get('batchID')
              ? '&batchID=' + searchParams.get('batchID')
              : ''
          }${
            searchParams.get('stage')
              ? '&formatted_stage=' + searchParams.get('stage')
              : ''
          }${
            searchParams.get('startDate')
              ? '&start_date=' + searchParams.get('startDate')
              : ''
          }${
            searchParams.get('endDate')
              ? '&end_date=' + searchParams.get('endDate')
              : ''
          }${
            searchParams.getAll('segments') &&
            searchParams.getAll('segments').length > 0
              ? '&segments=' + searchParams.getAll('segments').join(',')
              : ''
          }`,
        );
        const data = await response.json();
        setCustomers(data.leads);
        setTotalCustomers(data.total);
        setSelectedBatchID(data.batchId);
      } catch (err) {
        console.error('An error occurred while fetching leads:', err);
      } finally {
        setLoading(false);
      }
    };

    if (
      shouldFetch.current &&
      searchParams.get('current') &&
      searchParams.get('pageSize')
    ) {
      fetchCustomers();
      shouldFetch.current = false;
    }
  }, [searchParams, apiUrl, clientID]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      setTableState({
        ...pagination,
        ...filters,
        field: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setTableState({
        ...pagination,
        ...filters,
        field: 'lastCommunication',
        order: 'desc',
      });
    }
    console.log('tableState:', tableState);
    shouldFetch.current = true;
  };

  const handleFilterChanged = e => {
    if (e.target.value === 'processed') {
      setFilter('processed');
    } else if (e.target.value === 'focus') {
      setFilter('focus');
    } else {
      setFilter('all');
    }
    shouldFetch.current = true;
  };

  const filterOptions = [
    { label: 'Processed', value: 'processed' },
    { label: 'Focus', value: 'focus' },
    { label: 'All', value: 'all' },
  ];

  const columns = [
    {
      title: 'Client',
      dataIndex: ['first_name', 'last_name'],
      key: 'last_name',
      render: (text, record) => {
        return (
          <Space direction="vertical" size="small" style={{ display: 'flex' }}>
            <div className="client-name">
              <Title level={5}>
                {record.first_name} {record.last_name}
              </Title>
            </div>
            <div className="client-links">
              <a
                href={crmLink(record)}
                onAuxClick={e => {
                  e.stopPropagation();
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Button>CRM</Button>
              </a>
              <a
                href={`/lead/${clientID}/${record.id}/${record.task?.batch_id ? record.task.batch_id : ''}`}
                onAuxClick={e => {
                  e.stopPropagation();
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Button>Profile</Button>
              </a>
            </div>
          </Space>
        );
      },
      width: 175,
    },
    {
      title: 'Agent',
      dataIndex: ['agent', 'first_name', 'last_name'],
      key: 'agent',
      render: (text, record) => {
        return (
          <Space direction="vertical" size="small">
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '100px',
              }}
            >
              {record.agent?.first_name} {record.agent?.last_name}
            </div>
          </Space>
        );
      },
    },
    {
      title: 'Insight',
      dataIndex: ['segments'],
      key: 'segments',
      filteredValue: tableState.segments ? tableState.segments : [],
      filters: [
        { text: 'Past Offer', value: 'past_offer' },
        { text: 'Pre-Approval', value: 'pre_approval' },
        { text: 'In-Person Meeting', value: 'in_person_meeting' },
        { text: 'Buyer Agreement', value: 'signed_buyer_agreement' },
        {
          text: 'Customer Type',
          value: 'client_type',
          children: [
            { text: 'Homebuyer', value: 'client_type_homebuyer' },
            {
              text: 'Second Home/Vacation/Airbnb Buyer',
              value: 'client_type_2nd_home',
            },
            { text: 'Real Estate Investor', value: 'client_type_investor' },
            { text: 'Seller', value: 'client_type_seller' },
            { text: 'Land Buyer', value: 'client_type_land' },
            { text: 'Rent-Seeking Client', value: 'client_type_rent' },
          ],
        },
      ],
      sortOrder: tableState.field === 'segments' ? tableState.order : false,
      defaultSortOrder: 'desc',
      render: (text, record) => {
        return (
          <div>
            {record?.segments?.signed_buyer_agreement?.found ? (
              <Tooltip
                title={record?.segments?.signed_buyer_agreement.evidence}
              >
                <Tag color="cyan">Buyer Agreement</Tag>
              </Tooltip>
            ) : null}
            {record?.segments?.past_offer?.found ? (
              <Tooltip title={record?.segments?.past_offer?.evidence}>
                <Tag color="magenta">Past Offer</Tag>
              </Tooltip>
            ) : null}
            {record?.segments?.pre_approval?.found ? (
              <Tooltip title={record?.segments?.pre_approval?.evidence}>
                <Tag color="green">Pre-Approval</Tag>
              </Tooltip>
            ) : null}
            {record?.segments?.in_person_meeting?.found ? (
              <Tooltip title={record?.segments?.in_person_meeting?.evidence}>
                <Tag color="gold">Meeting</Tag>
              </Tooltip>
            ) : null}
            {record?.segments?.client_type?.self_evaluation
              ?.confidence_level === 'Absolutely Certain' ||
            record?.segments?.client_type?.self_evaluation?.confidence_level ===
              'High' ? (
              <Tooltip title={record.segments.client_type?.evidence}>
                <Tag color="purple">
                  {record.segments.client_type?.category ===
                  'Second Home/Vacation/Airbnb Buyer'
                    ? '2nd home'
                    : record.segments.client_type?.category ===
                        'Rent-Seeking Client'
                      ? 'Renter'
                      : record.segments.client_type?.category}
                </Tag>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
    {
      title: <div>Last Communication</div>,
      dataIndex: [
        'last_communication',
        'created_at_outbound',
        'created_at_inbound',
      ],
      onFilterDropdownOpenChange: visible => {
        setIsFilterDropdownVisible(visible);
        if (!visible) {
          setTableState(prevState => {
            if (
              prevState.startDate !== localStartDate ||
              prevState.endDate !== localEndDate
            ) {
              shouldFetch.current = true;
              return {
                ...prevState,
                startDate: localStartDate,
                endDate: localEndDate,
              };
            }
            return prevState;
          });
        }
      },
      filterDropdownOpen: isFilterDropdownVisible,
      filterOnClose: true,
      filterDropdown: (
        <span style={{ padding: '10px', width: '100%', height: '100%' }}>
          <Space direction="vertical" size="small">
            <Slider
              max={100}
              onChange={value => {
                setLocalStartDate(value[0]);
                setLocalEndDate(value[1]);
              }}
              range
              value={[localStartDate, localEndDate]}
            />
            <Space direction="horizontal">
              <Button
                onClick={() => {
                  setIsFilterDropdownVisible(false);
                  setTableState(prevState => {
                    if (
                      prevState.startDate !== localStartDate ||
                      prevState.endDate !== localEndDate
                    ) {
                      shouldFetch.current = true;
                      return {
                        ...prevState,
                        startDate: localStartDate,
                        endDate: localEndDate,
                      };
                    }
                    return prevState;
                  });
                }}
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  setLocalStartDate(0);
                  setLocalEndDate(100);
                }}
              >
                Reset
              </Button>
            </Space>
          </Space>
        </span>
      ),
      filtered: localStartDate !== 0 || localEndDate !== 100,
      key: 'lastCommunication',
      sorter: true,
      sortOrder:
        tableState.field === 'lastCommunication' ? tableState.order : false,
      defaultSortOrder: 'desc',
      render: (text, record) => {
        // We choose the min so default nulls to max date
        const outboundDate =
          record.last_communication.created_at_outbound != null
            ? new Date(record.last_communication.created_at_outbound)
            : new Date(-8640000000000000);
        const inboundDate =
          record.last_communication.created_at_inbound != null
            ? new Date(record.last_communication.created_at_inbound)
            : new Date(-8640000000000000);
        const lastCommunicationDate =
          outboundDate > inboundDate ? outboundDate : inboundDate;
        const now = new Date();
        const diffTime = Math.abs(now - lastCommunicationDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const wasOutbound = outboundDate > inboundDate;

        return (
          <div>
            {wasOutbound ? (
              <Tooltip title="Sent">
                <SendOutlined />
              </Tooltip>
            ) : (
              <Tooltip title="Received">
                <ReadOutlined />
              </Tooltip>
            )}
            &nbsp;
            {` ${diffDays} days ago`}
          </div>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: 'AI Insights',
      dataIndex: [
        'activity_summary',
        'task_note',
        'summary_of_conversation_in_less_than_20_words',
        'summary_of_note_in_less_than_30_words',
      ],
      key: 'action',
      render: (text, record) => (
        <div>
          <Descriptions
            items={[
              {
                label: 'Summary',
                span: 3,
                children:
                  record.activity_summary?.task_note
                    ?.summary_of_conversation_in_less_than_20_words,
              },
              {
                label: 'Next',
                span: 3,
                children:
                  record.activity_summary?.task_note
                    ?.summary_of_note_in_less_than_30_words,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const handleBatchClick = batchID => {
    setSelectedBatchID(batchID);
    shouldFetch.current = true;
  };

  const batchItems = batches.map((batch, index) => ({
    key: (index + 1).toString(),
    label: (
      <Tooltip title={batch.batch_info}>
        <div
          onClick={() => {
            handleBatchClick(batch.id);
          }}
        >
          {batch.label}
        </div>
      </Tooltip>
    ),
  }));

  const selectedBatchName = selectedBatchID
    ? batches.find(batch => batch.id === selectedBatchID)?.label
    : 'none';

  const defaultExpandable = {
    expandedRowRender: record => (
      <ExpandedLead
        activitySummary={record.activity_summary}
        apiUrl={apiUrl}
        batchId={record.task?.batch_id}
        clientId={clientID}
        isNoteGood={record.task?.is_action_good}
        isSummaryGood={record.task?.is_summary_good}
        leadId={record.id}
      />
    ),
    expandRowByClick: true,
  };

  return (
    <div className="CustomerList">
      <h2>Customer List</h2>

      {isAdmin() ? (
        <Space wrap>
          <Dropdown menu={{ items: batchItems }}>
            <a href="none" onClick={e => e.preventDefault()}>
              <Button>
                {selectedBatchName}
                <DownOutlined />
              </Button>
            </a>
          </Dropdown>
        </Space>
      ) : null}

      <Table
        columns={columns}
        dataSource={customers}
        expandable={defaultExpandable}
        loading={loading}
        locale={{
          emptyText: loading ? 'Fetching data...' : 'No data',
        }}
        onChange={handleTableChange}
        pagination={{
          current: parseInt(tableState.current),
          pageSize: parseInt(tableState.pageSize),
          total: totalCustomers,
        }}
        rowKey="id"
      />
    </div>
  );
}

LeadList.propTypes = {
  apiUrl: PropTypes.string.isRequired,
};

export default LeadList;
