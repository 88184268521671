import { Typography } from 'antd';
import CopyToClipboardButton from '../../CopyToClipboardButton';
const { Text } = Typography;

const boldify = (text: string): JSX.Element => {
  if (text.indexOf('**') === -1) {
    return <Text>{text}</Text>;
  }
  const splited = text.split('**');
  let open = false;

  return (
    <>
      {splited.map((text, i) => {
        open = !open;
        if (!open) {
          return (
            <Text key={i} strong>
              {text}
            </Text>
          );
        }
        return <Text key={i}>{text}</Text>;
      })}
    </>
  );
};

const boldifyForEmail = (text: string): string => {
  if (text.indexOf('**') === -1) {
    return `<span>${text}</span>`;
  }
  const splited = text.split('**');
  let open = false;

  let result = '';
  splited.forEach(text => {
    open = !open;
    if (!open) {
      result += `<strong>${text}</strong>`;
    } else {
      result += text;
    }
  });
  return result;
};

interface EmailProps {
  textFollowUp: string;
  emailSubjectFollowUp: string;
  emailFollowUp: string;
  is_voicemail: boolean;
}

const SmallLineBreak = () => (
  <br style={{ lineHeight: 4, content: ' ', fontSize: '5pt' }} />
);

export const EmailFollowUp = ({
  textFollowUp,
  emailFollowUp,
  emailSubjectFollowUp,
  is_voicemail,
}: EmailProps): JSX.Element => {
  return (
    <div
      style={{
        textAlign: 'left',
        background: 'white',
        fontFamily: 'Arial',
        fontSize: '11pt',
        padding: '10px',
      }}
    >
      {textFollowUp && (
        <>
          <Text strong>Text Follow Up Recommendation</Text>
          <SmallLineBreak />
          <SmallLineBreak />
          <Text>
            {textFollowUp}
            <CopyToClipboardButton textToCopy={textFollowUp} buttonLocation='text_followup' />
          </Text>
          <br />
          <br />
        </>
      )}
      {is_voicemail && emailFollowUp && (
        <>
          <Text strong>Email Follow Up Recommendation</Text>
          <SmallLineBreak />
          <SmallLineBreak />
          <Text strong>Subject: </Text>
          <Text>{emailSubjectFollowUp}</Text>
          <CopyToClipboardButton textToCopy={emailSubjectFollowUp} buttonLocation='email_followup_subject' />
          <br />
          <Text>
            {emailFollowUp.split('\n').map((text, i) => (
              <Text key={i}>
                {boldify(text)}
                <br />
              </Text>
            ))}
            <CopyToClipboardButton
              richTextToCopy={emailFollowUp
                .split('\n')
                .map(
                  (text, i) =>
                    `<div key=${i}>
                  ${boldifyForEmail(text)}
                  <br />
                </div>`,
                )
                .join('')}
              textToCopy={emailFollowUp}
              buttonLocation='email_followup_body'
            />
          </Text>
          <br />
          <br />
        </>
      )}
    </div>
  );
};
