import { Button, Flex, List, Space, Tag, Tooltip, Typography } from 'antd';
import { Task } from '../../types';
import { DislikeOutlined } from '@ant-design/icons';
import { crmLink } from '../../utils';
import { PushToSendBtn } from './push-to-send-btn';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';

const { Title, Paragraph, Text } = Typography;

const checkSegmentFound = (segment: any | undefined): boolean => {
  if (segment == null) {
    return false;
  }
  if (typeof segment === 'string') {
    return false;
  }
  return segment.found;
};

const getPersonalizationElements = (elements: string | string[]) => {
  if (typeof elements === 'string') {
    try {
      return JSON.parse(elements);
    } catch (error) {
      // Try to manually parse it
      return elements.replace('[', '').replace(']', '').split(',');
    }
  }
  return elements;
};

const getBuyerSegments = (task: Task) => {
  const segments = [];

  if (checkSegmentFound(task?.user_profile?.segments?.in_person_meeting)) {
    segments.push(
      <Tooltip
        title={task?.user_profile?.segments?.in_person_meeting?.evidence}
      >
        <Tag color="gold">Met In Person</Tag>
      </Tooltip>,
    );
  }
  if (checkSegmentFound(task?.user_profile?.segments?.signed_buyer_agreement)) {
    segments.push(
      <Tooltip
        title={task?.user_profile?.segments?.signed_buyer_agreement?.evidence}
      >
        <Tag color="cyan">Buyer Agreement</Tag>
      </Tooltip>,
    );
  }
  if (checkSegmentFound(task?.user_profile?.segments?.pre_approval)) {
    segments.push(
      <Tooltip title={task?.user_profile?.segments?.pre_approval?.evidence}>
        <Tag color="green">Pre-Approval</Tag>
      </Tooltip>,
    );
  }
  if (checkSegmentFound(task?.user_profile?.segments?.past_offer)) {
    segments.push(
      <Tooltip title={task?.user_profile?.segments?.past_offer?.evidence}>
        <Tag color="magenta">Past Offer</Tag>
      </Tooltip>,
    );
  }
  return segments;
};

interface TaskContentProps {
  task: Task;
  editMode: boolean;
  dislikeClicked: (task: Task) => Promise<void>;
  pushToSend: (task: Task, message?: string) => Promise<void>;
}

export const TaskContent = (props: TaskContentProps) => {
  const { task, dislikeClicked, pushToSend } = props;
  const [message, setMessage] = useState(
    task.task_result?.text_message || task.raw_task.text_message || '',
  );
  const [editMode, setEditMode] = useState(!task.checked_off && props.editMode);
  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Paragraph
        delete={task.checked_off}
        style={{
          fontFamily: 'Roboto',
          fontSize: 14,
          textAlign: 'left',
          whiteSpace: 'pre-line',
        }}
      >
        {task.description}
      </Paragraph>
      {task.raw_task.location && (
        <Paragraph
          delete={task.checked_off}
          style={{ fontFamily: 'Roboto', fontSize: 14, textAlign: 'left' }}
        >
          <Text strong>Location: </Text>
          {task.raw_task.location}
        </Paragraph>
      )}
      {task.task_type === 'missed_inbound' && task.raw_task.content && (
        <>
          <Title
            delete={task.checked_off}
            level={5}
            style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
          >
            Content
          </Title>
          <Paragraph
            delete={task.checked_off}
            style={{
              fontFamily: 'Roboto',
              fontSize: 14,
              textAlign: 'left',
              whiteSpace: 'pre-line',
            }}
          >
            {task.raw_task.content}
          </Paragraph>
        </>
      )}

      {task.task_type === 'call_follow_up' && (
        <span>
          <Title
            delete={task.checked_off}
            level={5}
            style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
          >
            Text Message
          </Title>
          {editMode ? (
            <TextArea
              autoSize
              style={{ verticalAlign: 'unset' }}
              onChange={e => setMessage(e.target.value)}
              //   rows={2}
              value={message}
            />
          ) : (
            <Paragraph
              delete={task.checked_off}
              style={{
                fontFamily: 'Roboto',
                fontSize: 14,
                textAlign: 'left',
                marginBottom: 0,
              }}
            >
              {message}
            </Paragraph>
          )}
        </span>
      )}
      {task?.raw_task?.justification_for_the_agent && (
        <span>
          <Title
            delete={task.checked_off}
            level={5}
            style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
          >
            Why
          </Title>
          <Paragraph
            delete={task.checked_off}
            style={{ fontFamily: 'Roboto', fontSize: 14, textAlign: 'left' }}
          >
            {task?.raw_task?.justification_for_the_agent}
          </Paragraph>
        </span>
      )}
      {task?.talking_points?.personalization_elements && (
        <span>
          <Title
            delete={task.checked_off}
            level={5}
            style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
          >
            Background Bytes
          </Title>

          <List
            dataSource={getPersonalizationElements(
              task.talking_points.personalization_elements,
            )}
            renderItem={(item: string) => (
              <List.Item
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 14,
                  textAlign: 'left',
                }}
              >
                <Text delete={task.checked_off}>{item}</Text>
              </List.Item>
            )}
            size="small"
          />
        </span>
      )}

      <Flex align="center" gap="xsmall" justify="flex-start" wrap="wrap">
        {task?.user_profile?.segments?.client_type?.category &&
          task?.user_profile?.segments?.client_type?.category !=
            'Homebuyer' && (
            <Tag color="red">
              {task?.user_profile?.segments?.client_type?.category}
            </Tag>
          )}
        {getBuyerSegments(task)}
      </Flex>
      <Flex
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          icon={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                backgroundColor: '#d3e5f6',
              }}
            >
              {task.rejected || task.rejected_by_admin ? (
                <DislikeOutlined
                  style={{
                    fontSize: '33px',
                    color: 'red',
                    transform: 'scaleX(-1)',
                  }}
                />
              ) : (
                <DislikeOutlined
                  style={{
                    fontSize: '33px',
                    color: '#28638A',
                    transform: 'scaleX(-1)',
                  }}
                />
              )}
            </div>
          }
          onClick={event => {
            dislikeClicked(task).catch(error =>
              console.error('Failed to toggle task completion:', error),
            );
            event.stopPropagation();
          }}
          shape="circle"
          style={{
            backgroundColor: 'transparent',
            height: '33px',
            width: '33px',
            padding: 0,
            marginRight: '10px', // Add some margin to separate the buttons
          }}
        />
        <Space>
          <a
            href={crmLink(task.lead)}
            onAuxClick={e => {
              e.stopPropagation();
            }}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Button ghost shape="round" type="primary">
              CRM
            </Button>
          </a>
          {task.task_type === 'call_follow_up' && (
            <>
              {!task.checked_off && (
                <Button
                  ghost
                  onClick={() => setEditMode(prev => !prev)}
                  shape="round"
                  type="primary"
                >
                  Edit
                </Button>
              )}

              <PushToSendBtn
                isCompleted={task.checked_off}
                pushToSend={() => pushToSend(task, message)}
              />
            </>
          )}
        </Space>
      </Flex>
    </Space>
  );
};
