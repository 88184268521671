import { Typography } from 'antd';
import CopyToClipboardButton from '../../CopyToClipboardButton';
import { AgentActionItem } from '../../types';
const { Text } = Typography;


interface ActionItemsProps {
    actionItems: AgentActionItem[];
}

const SmallLineBreak = () => (
    <br style={{ lineHeight: 4, content: ' ', fontSize: '5pt' }} />
);

export const ActionItems = ({
    actionItems,
}: ActionItemsProps): JSX.Element => {
    return (
        <div
            style={{
                textAlign: 'left',
                background: 'white',
                fontFamily: 'Arial',
                fontSize: '11pt',
                padding: '10px',
            }}        >
            <Text strong>Recommended Action Items</Text>
            <SmallLineBreak />
            {actionItems.map((item, index) => (
                <div key={index}>
                    <Text strong>Action: </Text>{item?.text}
                    <SmallLineBreak />
                    <Text strong>Recommended to finish by: </Text> {item?.due_datetime}
                </div>
            ))}
        </div>
    );
};
