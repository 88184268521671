import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CallFollowUpRawTask, Task } from '../../../types';
import { Button, GlobalToken, Input, Spin, Typography } from 'antd';
import PushToSendLeadContent from './push-to-send-lead-content';
import { RedoOutlined, SendOutlined } from '@ant-design/icons';
import PushToSendLeadBio from './pts-lead-bio';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

interface PushToSendTaskProps {
  task: Task;
  token: GlobalToken;
  isActive: boolean;
  apiUrl: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  agentId: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onTaskRemove: (taskId: string) => void;
  isEditing: boolean;
}

const PushToSendTask: React.FC<PushToSendTaskProps> = ({
  task,
  token,
  isActive,
  apiUrl,
  setAlertMessage,
  agentId,
  setError,
  onTaskRemove,
  isEditing,
  setLoading,
}) => {
  const [isEditingState, setIsEditingState] = useState(isEditing);
  const [textMessage, setTextMessage] = useState(
    task.talking_points.text_message,
  );
  const [rephraseLoading, setRephraseLoading] = useState(false);

  useLayoutEffect(() => {
    setIsEditingState(isEditing);
  }, [isEditing]);

  const handleTextClick = () => {
    setIsEditingState(true);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextMessage(e.target.value);
  };

  const handleTextBlur = () => {
    setIsEditingState(false);
  };

  const handleRegenerate = async (): Promise<void> => {
    try {
      setRephraseLoading(true);

      const response = await fetch(`${apiUrl}/api/rephrase/sms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: textMessage,
          recordingId: (task.raw_task as CallFollowUpRawTask).recording_id, // Probably should just rework the types to avoid this
        }),
      });
      const data = await response.json();

      setTextMessage(data.message);
    } catch (error) {
      console.error('Error regenerating text:', error);
    } finally {
      setRephraseLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${apiUrl}/api/pts/send_message/${task.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checked_off_by: agentId, // TODO come back to add user which is based on src/UserContext.tsx looks like for auth
          message: textMessage,
        }),
      });

      if (!res.ok) {
        throw new Error('Failed to send message');
      }

      onTaskRemove(task.id);
      setAlertMessage(
        `Follow-up sent to ${task.lead.first_name} ${task.lead.last_name}`,
      );
    } catch (error) {
      setError(
        'Failed to send message, please contact aiassistant@housewhisper.ai',
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Title
        level={4}
        style={{
          fontFamily: 'Roboto Slab',
          fontWeight: '400',
          marginTop: '10px',
          textAlign: 'left',
          color: '#28638A',
        }}
      >
        {task.title}
      </Title>
      <PushToSendLeadContent task={task} token={token} />
      {rephraseLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" tip="Rephrasing">
            <div className="ant-spin-content" />
          </Spin>
        </div>
      ) : (
        <div
          style={{
            marginTop: '10px',
            textAlign: 'left',
          }}
        >
          {isEditingState ? (
            <TextArea
              autoSize
              onBlur={handleTextBlur}
              onChange={handleTextChange}
              // ref={textAreaRef}
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '16px',
                color: '#28638A',
              }}
              value={textMessage}
            />
          ) : (
            <Paragraph
              onClick={handleTextClick}
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '16px',
                color: '#28638A',
              }}
            >
              {textMessage}
            </Paragraph>
          )}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Button
          icon={<RedoOutlined />}
          onClick={() => void handleRegenerate()}
          shape="round"
          type="default"
        >
          Rephrase
        </Button>
        <Button
          icon={<SendOutlined />}
          onClick={() => void handleSend()}
          shape="round"
          style={{ marginLeft: 'auto' }}
          type="primary"
        >
          Send
        </Button>
      </div>
      {task.lead_bio?.bio.short_bio &&
        task.lead_bio.bio.short_bio.trim() !== '' && (
          <PushToSendLeadBio shortBio={task.lead_bio.bio.short_bio} />
        )}
    </div>
  );
};

export default PushToSendTask;
