import React from 'react';

interface DotsProps {
  total: number;
  current: number;
}

const CustomDots: React.FC<DotsProps> = ({ total, current }) => {
  return (
    <div style={{ textAlign: 'center', paddingTop: 10 }}>
      {current + 1} of {total} whispers
    </div>
  );
};

export default CustomDots;
