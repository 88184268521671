import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Lead, TwilioConversation } from '../../types';
import { Button, Col, Divider, List, Row } from 'antd';

import { SYSTEM_PROMPT } from './prompts';
import { Conversation } from './Conversation';

export const OutreachPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const { clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [conversations, setConversations] = useState<TwilioConversation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeLead, setActiveLead] = useState<Lead | undefined>(undefined);
  const [selectedPrompt, setSelectedPrompt] = useState<string>('Rate Prompt');
  const [systemPrompt, setSystemPrompt] = useState<string>(SYSTEM_PROMPT);
  const leadId = searchParams.get('leadId');

  let offset = 0;
  useEffect(() => {
    offset = conversations?.length ?? 0;
  }, [conversations]);

  const fetchConversations = useCallback(async () => {
    try {
      setLoading(true);
      offset = Math.max(conversations.length - (leadId ? 1 : 0), 0);
      let url = `${apiUrl}/api/messaging/${clientId}?offset=${offset}`;
      const isLeadFetch = leadId && conversations.length === 0;
      if (isLeadFetch) {
        url += `&leadId=${leadId}`;
      }
      const response = await fetch(url);
      const jsonData = await response.json();
      setConversations(prev =>
        Array.from(
          new Map(
            [...(prev ?? []), ...jsonData.conversations].map(c => [c.id, c]),
          ).values(),
        ),
      );
      if (isLeadFetch) {
        setActiveLead(jsonData.conversations[0].lead);
      }
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
      setConversations([]);
    } finally {
      setLoading(false);
    }
  }, [clientId, conversations]);
  useEffect(() => {
    fetchConversations().then().catch(console.log);
  }, []);
  const loadMore = (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button
        loading={loading}
        onClick={() => {
          fetchConversations().then().catch(console.log);
        }}
      >
        load more
      </Button>
    </div>
  );

  return (
    <div>
      <h1>Outreach Page</h1>
      <p>Client ID: {clientId}</p>
      <Row gutter={16}>
        <Col sm={{ span: 7, order: 0 }} xs={{ span: 24, order: 1 }}>
          <List
            dataSource={conversations}
            loadMore={loadMore}
            // grid={{ gutter: 16, column: 4 }}
            renderItem={item => {
              let extra_style = {};
              if (item.lead_id == activeLead?.id) {
                extra_style = { backgroundColor: 'rgba(128, 128, 128, 0.2)' };
              }
              return (
                <List.Item
                  onClick={() => {
                    setActiveLead(item.lead);
                    setSearchParams({ leadId: item.lead_id });
                  }}
                  style={{
                    textAlign: 'left',
                    ...extra_style,
                  }}
                >
                  {item.lead.first_name} {item.lead.last_name}
                </List.Item>
              );
            }}
            style={{ overflow: 'auto', height: '80vh' }}
          />
        </Col>
        <Col sm={1} xs={0}>
          <Divider style={{ height: '95%' }} type="vertical" />
        </Col>
        <Col sm={{ span: 16, order: 2 }} xs={{ span: 24, order: 0 }}>
          {activeLead && (
            <Conversation
              apiUrl={apiUrl}
              lead={activeLead}
              selectedPrompt={selectedPrompt}
              setSelectedPrompt={setSelectedPrompt}
              setSystemPrompt={setSystemPrompt}
              systemPrompt={systemPrompt}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
