import React, { FC } from 'react';
import { Button } from 'antd';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';

interface FeedbackSelectorProps {
  value: boolean | null;
  setValue: (value: boolean | null) => void;
}

const FeedbackSelector: FC<FeedbackSelectorProps> = ({ value, setValue }) => {
  return (
    <div>
      <Button
        className="question-button"
        onClick={() => {
          setValue(value === true ? null : true);
        }}
        shape="circle"
        style={value ? { backgroundColor: 'green', borderColor: 'green' } : {}}
      >
        <SmileOutlined style={value ? { color: 'white' } : {}} />
      </Button>
      <Button
        className="question-button"
        onClick={() => {
          setValue(value === false ? null : false);
        }}
        shape="circle"
        style={
          value === false ? { backgroundColor: 'red', borderColor: 'red' } : {}
        }
      >
        <FrownOutlined style={value === false ? { color: 'white' } : {}} />
      </Button>
    </div>
  );
};

export default FeedbackSelector;
