import {
  Button,
  ConfigProvider,
  Flex,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { Task } from '../../types';

import { crmLink } from '../../utils';

import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { SubTaskTitle } from './lead-task-title';
import { Collapse } from 'react-collapse';
import TalkingPoints from './talking-points';
const { Title, Paragraph, Text } = Typography;

const checkSegmentFound = (segment: any | undefined): boolean => {
  if (segment == null) {
    return false;
  }
  if (typeof segment === 'string') {
    return false;
  }
  return segment.found;
};

const getBuyerSegments = (task: Task) => {
  const segments = [];

  if (checkSegmentFound(task?.user_profile?.segments?.in_person_meeting)) {
    segments.push(
      <Tooltip
        title={task?.user_profile?.segments?.in_person_meeting?.evidence}
      >
        <Tag color="gold">Met In Person</Tag>
      </Tooltip>,
    );
  }
  if (checkSegmentFound(task?.user_profile?.segments?.signed_buyer_agreement)) {
    segments.push(
      <Tooltip
        title={task?.user_profile?.segments?.signed_buyer_agreement?.evidence}
      >
        <Tag color="cyan">Buyer Agreement</Tag>
      </Tooltip>,
    );
  }
  if (checkSegmentFound(task?.user_profile?.segments?.pre_approval)) {
    segments.push(
      <Tooltip title={task?.user_profile?.segments?.pre_approval?.evidence}>
        <Tag color="green">Pre-Approval</Tag>
      </Tooltip>,
    );
  }
  if (checkSegmentFound(task?.user_profile?.segments?.past_offer)) {
    segments.push(
      <Tooltip title={task?.user_profile?.segments?.past_offer?.evidence}>
        <Tag color="magenta">Past Offer</Tag>
      </Tooltip>,
    );
  }
  return segments;
};

interface TaskContentProps {
  task: Task;

  message: string;
  setMessage: (message: string) => void;
  isSubtask?: boolean;
  taskAction: string | null;
}

interface CollapsableTaskContentProps {
  task: Task;
  pushToSend: (task: Task, message?: string) => Promise<void>;
  toggleTask: (task: Task) => Promise<void>;
  open: boolean;
  taskAction: string | null;
}

export const TaskContent = (props: TaskContentProps) => {
  const { task, message, setMessage } = props;
  const [editMode, setEditMode] = useState(props.taskAction === 'edit');
  return (
    <Space
      direction="vertical"
      size="small"
      style={{ width: '100%', marginTop: 2 }}
    >
      {task.task_type !== 'call_follow_up' && task.task_type !== 'time_hot' && (
        <Paragraph
          delete={task.checked_off}
          style={{
            fontFamily: 'Roboto',
            fontSize: 14,
            textAlign: 'left',
            whiteSpace: 'pre-line',
          }}
        >
          {task.description}
        </Paragraph>
      )}
      {task.raw_task.location && (
        <Paragraph
          delete={task.checked_off}
          style={{ fontFamily: 'Roboto', fontSize: 14, textAlign: 'left' }}
        >
          <Text strong>Location: </Text>
          {task.raw_task.location}
        </Paragraph>
      )}
      {task.task_type === 'missed_inbound' && task.raw_task.content && (
        <>
          <Title
            delete={task.checked_off}
            level={5}
            style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
          >
            Content
          </Title>
          <Paragraph
            delete={task.checked_off}
            style={{
              fontFamily: 'Roboto',
              fontSize: 14,
              textAlign: 'left',
              whiteSpace: 'pre-line',
            }}
          >
            {task.raw_task.content}
          </Paragraph>
        </>
      )}

      {task.task_type === 'call_follow_up' && (
        <span>
          {editMode ? (
            <TextArea
              autoSize
              style={{ verticalAlign: 'unset' }}
              onChange={e => setMessage(e.target.value)}
              //   rows={2}
              value={message}
            />
          ) : (
            <Paragraph
              delete={task.checked_off}
              onClick={() => setEditMode(prev => !prev)}
              style={{
                fontFamily: 'Roboto',
                fontSize: 14,
                textAlign: 'left',
                marginBottom: 0,
              }}
            >
              {message}
            </Paragraph>
          )}
        </span>
      )}
      <TalkingPoints task={task} />
      {task.task_type === 'time_hot' && (
        <Flex align="center" gap="xsmall" justify="flex-start" wrap="wrap">
          {task?.user_profile?.segments?.client_type?.category && (
            <Tag color="red">
              {task?.user_profile?.segments?.client_type?.category}
            </Tag>
          )}
          {getBuyerSegments(task)}
        </Flex>
      )}
      {!props.isSubtask && (
        <Flex justify="flex-end">
          <a
            href={crmLink(props.task.lead)}
            onAuxClick={e => {
              e.stopPropagation();
            }}
            onClick={e => {
              e.stopPropagation();
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: '#28638A',
                  },
                },
              }}
            >
              <Button
                ghost
                shape="round"
                style={{ fontFamily: 'Roboto Slab', fontWeight: 400 }}
                type="primary"
              >
                CRM
              </Button>
            </ConfigProvider>
          </a>
        </Flex>
      )}
    </Space>
  );
};

export const CollapsableTaskContent = (props: CollapsableTaskContentProps) => {
  const [open, setOpen] = useState(props.open);
  const [message, setMessage] = useState(
    props.task.task_result?.text_message ||
      props.task.raw_task.text_message ||
      '',
  );

  return (
    <div>
      <div onClick={() => setOpen(prev => !prev)}>
        <SubTaskTitle
          pushToSend={() => props.pushToSend(props.task, message)}
          task={props.task}
          toggleTask={props.toggleTask}
        />
      </div>

      <Collapse checkTimeout={30} isOpened={open}>
        <TaskContent
          {...props}
          isSubtask={true}
          message={message}
          setMessage={setMessage}
        />
      </Collapse>
    </div>
  );
};
