import { useMsal } from '@azure/msal-react';

const ALEX = '1034b072-2df5-4b92-a62b-4764d7611435';
const ANDREW = 'fada104c-e1b0-4614-b524-9358e7d3268f';
const ROLF = 'd8cd9ae9-74a2-4f15-9475-ca7ecab92555';
const LUIS = '64a3710b-6a1b-4426-b139-b32e2b3ae20d';
const NATE = '1bea7e9f-02c0-4e80-8e4e-f6061cdb221f';
const ALEXG = '1df29a4d-6818-4291-9eb7-52e41b3703d3';
const PORSHE = 'b8cf58c5-3058-4f2f-8a04-9681344cd928';
const ERIC = '5c14fcd6-752a-4ac9-8224-ee4eb873f9fd';
const VICTOR = '742a5a5e-1d1b-4477-96cd-23dd70151a62';

const ADMINS = [ALEX, ANDREW, ROLF, LUIS, ERIC, VICTOR];

const USERS: Record<number, string[]> = {
  1: [],
  2: [NATE, ALEXG, PORSHE],
};

export function isAdmin(): boolean {
  // Move the function inside a React function component and rename it to start with an uppercase letter
  function IsAdmin(): boolean {
    const { accounts } = useMsal();
    if (!accounts[0]) {
      return false;
    }
    return ADMINS.includes(accounts[0].localAccountId);
  }

  return IsAdmin();
}

export function isUserLoggedIn(): boolean {
  // Move the function inside a React function component and rename it to start with an uppercase letter
  function IsUserLoggedIn(): boolean {
    const { accounts } = useMsal();
    console.log('accounts', accounts, !!accounts[0]);
    return !!accounts[0];
  }

  return IsUserLoggedIn();
}

export function canViewData(customerID: number): boolean {
  // Move the function inside a React function component and rename it to start with an uppercase letter
  function CanViewData(): boolean {
    const { accounts } = useMsal();
    if (!accounts[0]) {
      return false;
    }
    return (
      ADMINS.includes(accounts[0].localAccountId) ||
      USERS[customerID].includes(accounts[0].localAccountId)
    );
  }

  return CanViewData();
}
