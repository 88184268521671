import {
  CalendarOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Task } from '../../types';
import { theme } from 'antd';

export interface TaskIconProps {
  task: Task;
  toggleTask: (task: Task) => Promise<void>;
  pushToSend: () => Promise<void>;
}

export default function TaskIcon(props: TaskIconProps) {
  const { task, toggleTask, pushToSend } = props;
  const { token } = theme.useToken();
  if (task.checked_off) {
    return (
      <CheckCircleFilled
        onClick={event => {
          event.stopPropagation();
          void toggleTask(task);
        }}
        style={{ color: token.colorPrimary }}
      />
    );
  }
  const checkCircle = (
    <CheckCircleOutlined
      onClick={event => {
        event.stopPropagation();
        void toggleTask(task);
      }}
      style={{ color: '#28638A' }}
    />
  );
  switch (task.task_type) {
    case 'appointment':
      return <CalendarOutlined style={{ color: '#28638A' }} />;
    case 'call_follow_up':
      if (task.client_id !== 1) {
        return checkCircle;
      }
      return (
        <SendOutlined
          onClick={event => {
            event.stopPropagation();
            void pushToSend();
          }}
          style={{ color: '#28638A' }}
        />
      );
    default:
      return checkCircle;
  }
}
