import React from 'react';
import { Button, Carousel } from 'antd';
import './styles.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface Message {
  message: string;
}

interface SendListingsCarouselDisplayProps {
  messages: Message[];
}

const SendListingsCarouselDisplay: React.FC<
  SendListingsCarouselDisplayProps
> = ({ messages }) => {
  const handleEdit = () => {
    console.log('TODO allow edit message');
  };

  const sendListing = () => {
    console.log('TODO: Send listing');
  };

  return (
    <div
      style={{
        backgroundColor: '#f0f0f0',
        padding: 20,
        marginTop: 20,
        borderRadius: 8,
      }}
    >
      <img
        alt="Home"
        src="https://d25fhp1qfwqa2h.cloudfront.net/9374971598_auto_650"
        style={{
          width: '90%',
          maxHeight: '100px',
          marginBottom: 10,
          objectFit: 'cover',
        }}
      />
      <Carousel
        arrows
        className="carousel-custom"
        dots={false}
        infinite={false}
        nextArrow={<RightOutlined />}
        prevArrow={<LeftOutlined />}
      >
        {messages.map((item, index) => (
          <div className="slick-slide" key={index}>
            <h3 className="carousel-text">{item.message}</h3>
          </div>
        ))}
      </Carousel>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <Button onClick={handleEdit} style={{ marginTop: 10 }}>
          Edit
        </Button>
        <Button onClick={sendListing} style={{ marginTop: 10 }} type="primary">
          Send
        </Button>
      </div>
    </div>
  );
};

export default SendListingsCarouselDisplay;
