import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';

interface ScoreFeedbackSelectorProps {
  value: string | null;
  setValue: (value: string | null) => void;
}

export const THUMBS_UP = 'thumbs-up';
export const THUMBS_DOWN = 'thumbs-down';
export const THUMBS_SIDEWAYS = 'meh';

const ScoreFeedbackSelector: FC<ScoreFeedbackSelectorProps> = ({
  value,
  setValue,
}) => {
  return (
    <div>
      <Tooltip title="High Priority Lead: Likely to convert and should be followed up immediately">
        <Button
          className="question-button"
          onClick={() => {
            setValue(value === THUMBS_UP ? null : THUMBS_UP);
          }}
          shape="circle"
          style={
            value === THUMBS_UP
              ? { backgroundColor: 'green', borderColor: 'green' }
              : {}
          }
        >
          <SmileOutlined
            style={value === THUMBS_UP ? { color: 'white' } : {}}
          />
        </Button>
      </Tooltip>

      <Tooltip title="Medium Priority Lead: Has potential but is not urgent; follow-up can be scheduled appropriately.">
        <Button
          className="question-button"
          onClick={() => {
            setValue(value === THUMBS_SIDEWAYS ? null : THUMBS_SIDEWAYS);
          }}
          shape="circle"
          style={
            value === THUMBS_SIDEWAYS
              ? { backgroundColor: 'orange', borderColor: 'orange' }
              : {}
          }
        >
          <MehOutlined
            style={value === THUMBS_SIDEWAYS ? { color: 'white' } : {}}
          />
        </Button>
      </Tooltip>

      <Tooltip title="Low Priority Lead: Unlikely to convert soon and requires minimal or no follow-up at this stage.">
        <Button
          className="question-button"
          onClick={() => {
            setValue(value === THUMBS_DOWN ? null : THUMBS_DOWN);
          }}
          shape="circle"
          style={
            value === THUMBS_DOWN
              ? { backgroundColor: 'red', borderColor: 'red' }
              : {}
          }
        >
          <FrownOutlined
            style={value === THUMBS_DOWN ? { color: 'white' } : {}}
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export default ScoreFeedbackSelector;
