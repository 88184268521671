import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useParams } from 'react-router-dom';
import { Lead } from '../../types';
import { crmLink } from '../../utils';
import moment from 'moment';

interface NurtureLeadsProps {
  apiUrl: string;
}

const NurtureLeads: React.FC<NurtureLeadsProps> = ({ apiUrl }) => {
  const { clientId, agentId } = useParams<{
    clientId: string;
    agentId: string;
  }>();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [stageCounts, setStageCounts] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchLeads = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${apiUrl}/api/leads2/${clientId}/${agentId}`,
        );
        if (!response.ok) {
          throw new Error('Failed to fetch leads');
        }
        const data = await response.json();
        setLeads(data);

        // Calculate stage counts
        const counts: { [key: string]: number } = {};
        data.forEach((lead: Lead) => {
          counts[lead.stage || 'NONE'] =
            (counts[lead.stage || 'NONE'] || 0) + 1;
        });
        setStageCounts(counts);
      } catch (error) {
        message.error('Failed to load leads. Please try again later.');
        console.error('Error fetching leads:', error);
      } finally {
        setLoading(false);
      }
    };

    void fetchLeads();
  }, [apiUrl, clientId, agentId]);

  const renderLastCommunication = (created_at: string) => {
    const now = moment();
    const createdAt = moment(created_at);
    const diffDays = now.diff(createdAt, 'days');
    const diffMonths = now.diff(createdAt, 'months');

    if (diffMonths > 3) {
      return `${diffMonths} months ago`;
    }
    return `${diffDays} days ago`;
  };

  const columns: ColumnsType<Lead> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a
          href={crmLink({
            client_id: record?.client_id || 0,
            source_id: record?.source_id || '0',
          })}
        >
          {record.first_name} {record.last_name}
        </a>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'bio',
      dataIndex: 'lead_bios',
      key: 'lead_bios',
      render: (text, record) => {
        console.log('lead_bios:', record); // Debugging line
        return record.lead_bio ? 'T' : 'F';
      },
      filters: [
        {
          text: 'True',
          value: 'T',
        },
        {
          text: 'False',
          value: 'F',
        },
      ],
      onFilter: (value, record) => {
        if (value === 'T') {
          return !!record.lead_bio;
        }
        return !record.lead_bio;
      },
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      filters: Object.keys(stageCounts).map(stage => ({
        text: `${stage} (${stageCounts[stage]})`,
        value: stage,
      })),
      onFilter: (value, record) => record.stage === value,
    },
    {
      title: 'last communication',
      dataIndex: ['last_communication', 'created_at'],
      key: 'last_communication.created_at',
      sorter: (a, b) => {
        if (a.last_communication && b.last_communication) {
          return (
            new Date(a.last_communication.created_at).getTime() -
            new Date(b.last_communication.created_at).getTime()
          );
        }
        return 0;
      },
      render: created_at => renderLastCommunication(created_at),
    },

    // Add other columns as necessary
  ];

  return (
    <div>
      <h1>Leads</h1>
      <Table
        columns={columns}
        dataSource={leads}
        expandable={{
          expandedRowRender: record => (
            <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
              {record.summary}
            </p>
          ),
        }}
        loading={loading}
        rowKey="id"
      />
    </div>
  );
};

export default NurtureLeads;
