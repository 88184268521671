import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Progress,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import './LeadDetail.css';
import ScoreFeedbackSelector from './ScoreFeedbackSelector';
import { UserContext } from '../UserContext';
import { CommunicationModal } from './CommunicationModal';
import PropTypes from 'prop-types';

const PREFERRED_PROFILE_ORDER = [
  'time_frame',
  'engagement_level',
  'already_purchased_home',
  'gender',
  'children',
  'bio',
  'occupation',
  'age',
  'motivation',
  'location_motivation',
  'interests',
  'marital_status',
];
const DELETE_PROFILE = ['detailed_client_bio'];
const PREFERRED_BUDGET_ORDER = [
  'income',
  'credit_score',
  'down_payment',
  'preapproval',
];
const PREFERRED_HOME_ORDER = [
  'lower_budget',
  'upper_budget',
  'desired_bedrooms',
  'desired_bathrooms',
  'desired_sqft',
  'desired_stories',
  'location',
];

function LeadDetail({ apiUrl }) {
  const [lead, setLead] = useState(null);
  const { leadID } = useParams(); // Get the leadID from the URL
  const { clientID } = useParams(); // Get the ClientName from the URL
  const { batchID } = useParams(); // Get the batchID from the URL
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigator = useNavigate();
  const { Title } = Typography;
  const [scoreFeedback, setScoreFeedback] = useState(null);
  const [isActionGood, setIsActionGood] = useState(null);
  const [isSummaryGood, setIsSummaryGood] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [serverStateFeedback, setServerStateFeedback] = useState('');
  const firstFetch = useRef(true);
  const firstFeedbackPost = useRef(true);
  const { Text } = Typography;
  const [communications, setCommunications] = useState(null);
  const user = useContext(UserContext);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const fetchLead = async () => {
      try {
        let url = `${apiUrl}/api/Lead/${clientID}/${leadID}`;
        if (batchID) {
          url += `/${batchID}`;
        }
        const response = await fetch(url);
        const leadData = await response.json();
        setLead(leadData);
        if (leadData.hasOwnProperty('task') && leadData.task) {
          setScoreFeedback(leadData.task.score_feedback);
          setIsActionGood(leadData.task.is_action_good);
          setIsSummaryGood(leadData.task.is_summary_good);
          setFeedback(leadData.task.note || '');
          setServerStateFeedback(leadData.task.note || '');
        }
        setIsDataLoaded(true);
        console.log('useEffect leadData', leadData);

        const communicationsResponse = await fetch(
          `${apiUrl}/api/Communications/${clientID}/${leadID}`,
        );
        const communicationsData = await communicationsResponse.json();
        setCommunications(communicationsData);
      } catch (err) {
        console.error('An error occurred while fetching the lead:', err);
      }
    };
    // hack because I keep getting two calls to the server
    if (firstFetch.current) {
      fetchLead();
      firstFetch.current = false;
    }
  }, [apiUrl, leadID, clientID, batchID]);

  useEffect(() => {
    console.log('useEffect user', user);
    const updateFeedbackTask = async () => {
      try {
        const url = `${apiUrl}/api/UpdateFeedbackTask/${clientID}/${leadID}/${batchID}`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'user-id': user?.userID,
            'user-name': user?.name,
          },
          body: JSON.stringify({
            scoreFeedback,
            isActionGood,
            isSummaryGood,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (err) {
        console.error('An error occurred while updating the status:', err);
      }
    };

    if (isDataLoaded) {
      // make sure we don't call the server on the first render only on param updates
      if (firstFeedbackPost.current) {
        firstFeedbackPost.current = false;
        return;
      }
      updateFeedbackTask();
    }
  }, [
    apiUrl,
    scoreFeedback,
    isActionGood,
    isSummaryGood,
    isDataLoaded,
    clientID,
    leadID,
    batchID,
    user,
  ]);

  // Leaving some checks in here to ensure we don't post for no reason but it doesn't really seem to be needed
  const postFeedback = async feedbackToSave => {
    if (feedbackToSave !== serverStateFeedback) {
      const url = `${apiUrl}/api/UpdateFeedbackTask/${clientID}/${leadID}/${batchID}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'user-id': user?.userID,
          'user-name': user?.name,
        },
        body: JSON.stringify({
          scoreFeedback,
          isActionGood,
          isSummaryGood,
          note: feedbackToSave,
        }),
      });
      setServerStateFeedback(feedbackToSave);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }
  };

  const handleFeedbackChange = event => {
    clearTimeout(timer);
    setFeedback(event.target.value);

    const newTimer = setTimeout(() => {
      saveFeedback(event.target.value);
    }, 1500); // 1.5 seconds

    setTimer(newTimer);
  };

  const saveFeedback = text => {
    postFeedback(text);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBackClick = () => {
    window.history.back();
  };

  const handleExtractClick = () => {
    navigator(`/playground/${clientID}/${leadID}/?field=extract`);
  };

  const handleFunnelClick = () => {
    navigator(`/playground/${clientID}/${leadID}/?field=funnel`);
  };

  const handleNewTabClick = () => {
    if (clientID === '1') {
      window.open(
        `https://client14.sierrainteractivedev.com/lead-detail.aspx?id=${lead.source_id}`,
        '_blank',
      );
    } else if (clientID === '2') {
      window.open(
        `https://carolinaone2.followupboss.com/2/people/view/${lead.source_id}`,
        '_blank',
      );
    }
  };

  function sortKeysByPreferredOrder(keyA, keyB, preferredOrder) {
    const indexA = preferredOrder.indexOf(keyA);
    const indexB = preferredOrder.indexOf(keyB);
    if (indexA === -1) {
      return 1;
    }
    if (indexB === -1) {
      return -1;
    }
    return indexA - indexB;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let formattedDateString = '';
    if (diffDays === 1) {
      formattedDateString = 'Yesterday';
    } else if (diffDays <= 7) {
      formattedDateString = `${diffDays} days ago`;
    } else if (diffDays <= 30) {
      formattedDateString = `${Math.ceil(diffDays / 7)} weeks ago`;
    } else {
      formattedDateString = 'on ' + date.toLocaleDateString();
    }
    return (
      <Tooltip title={new Date(dateString).toLocaleString()}>
        {formattedDateString}
      </Tooltip>
    );
  }

  const getValue = value => {
    if (value == null) {
      return 'N/A';
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value;
  };

  const renderBio = bio => {
    if (bio == null) {
      return null;
    }
    let bioText;
    if (typeof bio === 'string') {
      bioText = bio;
    } else if (bio.value != null) {
      bioText = bio.value;
    }

    return bioText ? (
      <Card
        title={
          <div className="title-container">
            <span>Bio</span>
          </div>
        }
      >
        <p>
          {typeof lead.user_profile.detailed_client_bio === 'string'
            ? lead.user_profile.detailed_client_bio
            : lead.user_profile.detailed_client_bio.reason}
        </p>
      </Card>
    ) : null;
  };

  function renderProfileElements(map, sortOrder, deleteKeys = []) {
    return Object.entries(map || {})
      .filter(([key]) => !deleteKeys.includes(key))
      .sort((a, b) => sortKeysByPreferredOrder(a[0], b[0], sortOrder))
      .filter(
        ([key, value]) =>
          value != null &&
          ((typeof value === 'string' && value != null) ||
            value.reason != null ||
            value.value != null),
      )
      .map(([key, value], index) => {
        if (typeof value === 'string') {
          return {
            key: String(index),
            label: key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            children: <span>{value || 'N/A'}</span>,
          };
        } else if (typeof value === 'object') {
          return {
            key: String(index),
            label: key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            children: (
              <Tooltip title={value.reason}>
                <span>{getValue(value.value)}</span>
              </Tooltip>
            ),
          };
        }
        return null;
      });
  }

  const getPercentFromConfidenceLevel = confidenceLevel => {
    switch (confidenceLevel) {
      case 'Absolutely Certain':
        return 100;
      case 'High':
        return 75;
      case 'Moderate':
        return 50;
      case 'Low':
        return 25;
      default:
        return 0;
    }
  };

  const segment_items =
    lead && lead?.segments
      ? Object.entries(lead.segments).map(([key, value], index) => {
          if (key === 'user_timeliness') {
            return {};
          }
          return {
            key: String(key),
            label: key
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            children: (
              <div>
                <Tooltip title={value.evidence}>
                  <span>
                    {value.found || typeof value.found === 'boolean'
                      ? getValue(value.found)
                      : value.category}
                  </span>
                </Tooltip>
                <Tooltip title={value?.self_evaluation?.justification}>
                  <Progress
                    format={percent => `${percent} `}
                    percent={getPercentFromConfidenceLevel(
                      value?.self_evaluation?.confidence_level,
                    )}
                    size={20}
                    type="circle"
                  />
                </Tooltip>
              </div>
            ),
          };
        })
      : [];

  const leadBioItems =
    lead && lead?.lead_bio?.bio
      ? [
          {
            key: 'family',
            label: 'Family',
            children: (
              <div>
                <p>Age: {lead.lead_bio.bio.family.age}</p>
                <p>Pets: {lead.lead_bio.bio.family.pets}</p>
                <p>Spouse: {lead.lead_bio.bio.family.spouse}</p>
                <p>Children: {lead.lead_bio.bio.family.children}</p>
                <p>Grandchildren: {lead.lead_bio.bio.family.grandchildren}</p>
              </div>
            ),
          },
          {
            key: 'reason',
            label: 'Reason for Buying',
            children: <div>{lead.lead_bio.bio.reason}</div>,
          },
          {
            key: 'location',
            label: 'Location',
            children: <div>{lead.lead_bio.bio.location}</div>,
          },
          {
            key: 'short_bio',
            label: 'Short Bio',
            children: <div>{lead.lead_bio.bio.short_bio}</div>,
          },
          {
            key: 'client_type',
            label: 'Client Type',
            children: <div>{lead.lead_bio.bio.client_type.category}</div>,
          },
          {
            key: 'property_details',
            label: 'Property Details',
            children: (
              <div>
                <p>Price: {lead.lead_bio.bio.property_details.price}</p>
                <p>Bedrooms: {lead.lead_bio.bio.property_details.bedrooms}</p>
                <p>Features: {lead.lead_bio.bio.property_details.features}</p>
                <p>Bathrooms: {lead.lead_bio.bio.property_details.bathrooms}</p>
              </div>
            ),
          },
          {
            key: 'selling_current_house',
            label: 'Selling Current House',
            children: <div>{lead.lead_bio.bio.selling_current_house}</div>,
          },
          {
            key: 'is_open_to_other_locations',
            label: 'Open to Other Locations',
            children: (
              <div>
                {lead.lead_bio.bio.is_open_to_other_locations ? 'Yes' : 'No'}
              </div>
            ),
          },
        ]
      : [];

  return (
    <div className="LeadDetail">
      {lead ? (
        <div>
          <div className="title-container">
            <Title level={2} orientation="left">
              {lead.first_name + ' ' + lead.last_name}
            </Title>
            {lead.hasOwnProperty('task') && lead.task ? (
              <ScoreFeedbackSelector
                setValue={setScoreFeedback}
                value={scoreFeedback}
              />
            ) : null}
          </div>
          <div>
            {renderBio(lead.user_profile?.detailed_client_bio)}
            <Card
              title={
                <div className="title-container">
                  <span>User Profile</span>
                </div>
              }
            >
              <Descriptions
                items={renderProfileElements(
                  lead.user_profile,
                  PREFERRED_PROFILE_ORDER,
                  DELETE_PROFILE,
                )}
              />
              <Divider orientation="left" plain>
                Bio
              </Divider>
              <Descriptions items={leadBioItems} />
              <Divider orientation="left" plain>
                User Factors
              </Divider>
              <Descriptions items={segment_items} />
              <Divider orientation="left" plain>
                Budget
              </Divider>
              <Descriptions
                items={renderProfileElements(
                  lead.user_budget,
                  PREFERRED_BUDGET_ORDER,
                )}
              />
              <Divider orientation="left" plain>
                Home
              </Divider>
              <Descriptions
                items={renderProfileElements(
                  lead.home_attributes,
                  PREFERRED_HOME_ORDER,
                )}
              />
            </Card>
          </div>
          {lead.hasOwnProperty('task') && lead.task ? (
            <Card title={'Additional Feedback'}>
              <textarea
                className="feedback-textarea"
                onChange={handleFeedbackChange}
                value={feedback}
              />
            </Card>
          ) : null}

          <Button onClick={showModal} type="primary">
            Show Communications
          </Button>
          <Button onClick={handleNewTabClick} type="primary">
            Open in CRM
          </Button>
          <Button
            disabled={feedback !== serverStateFeedback}
            onClick={handleBackClick}
          >
            {feedback === serverStateFeedback ? 'Back' : <Spin />}
          </Button>

          <div>
            <Button onClick={handleExtractClick}>Refine Extraction </Button>
            <Button onClick={handleFunnelClick}>RefineFunnel</Button>
          </div>

          <CommunicationModal
            closeModal={handleCancel}
            communications={communications?.communications}
            isModalVisible={isModalVisible}
          />
        </div>
      ) : (
        <div>
          <p>Loading...</p>
          <Button onClick={handleBackClick}>Back</Button>
        </div>
      )}
    </div>
  );
}

LeadDetail.propTypes = {
  apiUrl: PropTypes.string.isRequired,
};

export default LeadDetail;
