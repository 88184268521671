import { CheckOutlined, SendOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const PushToSendBtn = (props: {
  isCompleted: boolean;
  pushToSend: () => Promise<void>;
}) => {
  if (!props.isCompleted) {
    return (
      <Button
        icon={<SendOutlined />}
        onClick={() => void props.pushToSend()}
        shape="round"
        style={{ backgroundColor: '#28638A' }}
        type="primary"
      >
        Send
      </Button>
    );
  }
  return (
    <Button icon={<CheckOutlined />} shape="round" type="primary">
      Sent
    </Button>
  );
};
