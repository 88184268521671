// Coaching.tsx
import React from 'react';
import {
    Collapse,
    CollapseProps,
    Flex,
    Typography,
} from 'antd';
const { Text } = Typography;


import { CoachingItem } from '../../types';
interface CoachingProps {
    coaching: CoachingItem[];
}

function groupByQuality(coaching: CoachingItem[]) {
    return coaching.reduce((groups: { [key: string]: CoachingItem[] }, item) => {
        let qualityStatement: string;
        if (item.quality === 'excellent') {
            qualityStatement = 'You\'re doing a phenomenal job! Let\'s look at some of the highlights and see what makes your approach so effective';
        } else if (item.quality === 'ok') {
            qualityStatement = 'You\'re on the right track, and there are some areas we can polish to elevate your performance even further';
        } else if (item.quality === 'room to improve') {
            qualityStatement = 'I admire your determination. There are areas we can focus on to help you reach your full potential:';
        } else {
            qualityStatement = item.quality;
        }
        const group = (groups[qualityStatement] || []);
        group.push(item);
        groups[qualityStatement] = group;
        return groups;
    }, {});
}

export const Coaching: React.FC<CoachingProps> = ({ coaching }) => {
    const groupedCoaching = groupByQuality(coaching);

    const items: CollapseProps['items'] = Object.entries(groupedCoaching).map(([quality, coaches]) => ({
        key: quality,
        label: (
            <span>
                <Flex gap={0} vertical>
                    <Flex align="center" gap="small" justify="flex-start" wrap="wrap">
                        <Text
                            style={{
                                fontFamily: 'Roboto',
                                fontSize: '16px',
                                //color: quality === 'You can grow as a agent by doing:' ? 'red' :
                                //    quality === 'You are doing okay, but there is room for improvement:' ? 'blue' :
                                //        quality === 'Excellent Job, you did great with the following:' ? 'green' : 'black',
                            }}
                        >
                            {quality}
                        </Text>
                    </Flex>
                </Flex>
            </span>
        ),
        children: coaches.map((coach, index) => (
            <ul key={index}>
                <li>
                    <Text><b>{coach.classify}</b> - {coach.mentor} <b> Why is this important? </b> {coach.why}</Text>
                </li>
            </ul>
        )),
        style: {
            marginBottom: 24,
            background: '#e0e3e8',
            border: 'none',
        },
        showArrow: false,
    }));

    return (
        <div style={{
            textAlign: 'left',
            background: 'white',
            fontFamily: 'Arial',
            fontSize: '11pt',
            padding: '10px',
        }}>
            <Text strong>Coaching</Text>
            <Collapse defaultActiveKey={['1']} ghost items={items} />
        </div>
    );
};