import React from 'react';
import { Descriptions, Divider, List, Typography } from 'antd';
import { Task } from '../../types';

import {
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

interface TalkingPointsProps {
  task: Task;
}

const getPersonalizationElements = (elements: string | string[]) => {
  if (typeof elements === 'string') {
    try {
      return JSON.parse(elements);
    } catch (error) {
      // Try to manually parse it
      return elements.replace('[', '').replace(']', '').split(',');
    }
  }
  return elements;
};

function daysAgo(conversationDate: string): string {
  const date = new Date(conversationDate);
  const now = new Date();
  const differenceInTime = now.getTime() - date.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return `${differenceInDays} days ago`;
}

const TalkingPoints: React.FC<TalkingPointsProps> = ({ task }) => {
  const bio_items = [
    {
      key: '1',
      label: 'About',
      children: task?.lead_bio?.bio.short_bio,
      span: 2,
    },
    {
      key: '2',
      label: 'Location',
      children: task?.lead_bio?.bio.location,
    },
    {
      key: '3',
      label: 'Price',
      children: task?.lead_bio?.bio.property_details?.price,
    },
    {
      key: '4',
      label: 'Beds',
      children: task?.lead_bio?.bio.property_details?.bedrooms,
    },
    {
      key: '5',
      label: 'Baths',
      children: task?.lead_bio?.bio.property_details?.bathrooms,
    },
    {
      key: '6',
      label: 'Features',
      children: task?.lead_bio?.bio.property_details?.features,
    },
    {
      key: '7',
      label: 'Contact',
      children: (
        <span>
          {task?.talking_points?.last_communication_talking_points?.communication_preferences?.preferred_channel
            ?.toLowerCase()
            .includes('phone') && <PhoneOutlined />}
          {task?.talking_points?.last_communication_talking_points?.communication_preferences?.preferred_channel
            ?.toLowerCase()
            .includes('text') && <MessageOutlined />}
          {task?.talking_points?.last_communication_talking_points?.communication_preferences?.preferred_channel
            ?.toLowerCase()
            .includes('email') && <MailOutlined />}
        </span>
      ),
    },
  ];

  // Filter out items where children is null, undefined, empty string, or "unknown"
  const filteredBioItems = bio_items.filter(item => {
    if (typeof item.children === 'string') {
      return item.children && item.children.toLowerCase() !== 'unknown';
    } else if (item.children instanceof Element) {
      return true;
    } else if (typeof item.children === 'number') {
      return true;
    }
    return false;
  });
  // Now, use filteredItems for rendering
  return (
    <span>
      {task?.talking_points?.last_communication_talking_points && (
        <span>
          <Paragraph>
            {
              task?.talking_points?.last_communication_talking_points
                .summary_of_last_communications
            }
          </Paragraph>
          <Title
            delete={task.checked_off}
            level={5}
            style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
          >
            Ice Breakers
          </Title>
          <List
            dataSource={getPersonalizationElements(
              task.talking_points.last_communication_talking_points
                .personalization_elements,
            )}
            renderItem={(item: string) => (
              <List.Item
                style={{
                  fontFamily: 'Roboto',
                  fontSize: 14,
                  textAlign: 'left',
                  paddingLeft: 0,
                }}
              >
                <Text delete={task.checked_off}>{item}</Text>
              </List.Item>
            )}
            size="small"
          />
          {filteredBioItems.length > 0 && (
            <>
              <Divider />
              <Title
                delete={task.checked_off}
                level={5}
                style={{ fontFamily: 'Roboto Slab', textAlign: 'left' }}
              >
                Client Bio
              </Title>
              <Descriptions items={filteredBioItems} />
            </>
          )}
        </span>
      )}
    </span>
  );
};

export default TalkingPoints;
