import { Button, List, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Recording } from '../../types';
import { CollapsableCard } from './CollapsableCard';

export const RecordingsPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const { clientId } = useParams();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const [recordings, setRecordings] = useState<Recording[] | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(true);
  let offset = 0;
  useEffect(() => {
    offset = recordings?.length ?? 0;
  }, [recordings]);

  const fetchRecordings = async () => {
    try {
      setLoading(true);
      let url = `${apiUrl}/api/recordings/${clientId}?offset=${offset}`;
      if (searchParams.get('useCommunications') === 'true') {
        url = `${url}&useCommunications=true`;
      }
      if (searchParams.get('lead_id')) {
        url = `${url}&lead_id=${searchParams.get('lead_id')}`;
      }
      const response = await fetch(url);
      const jsonData = await response.json();
      setRecordings(prev => [...(prev ?? []), ...jsonData.recordings]);
    } catch (err) {
      console.error('An error occurred while fetching the lead:', err);
      setRecordings([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRecordings().then().catch(console.log);
  }, []);
  const loadMore = (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button
        loading={loading}
        onClick={() => {
          fetchRecordings().then().catch(console.log);
        }}
      >
        load more
      </Button>
    </div>
  );
  return (
    <div>
      <h1>Recordings</h1>
      <List
        dataSource={recordings}
        loadMore={loadMore}
        // grid={{ gutter: 16, column: 4 }}
        renderItem={item => (
          <List.Item>
            <Space direction="vertical">
              <CollapsableCard apiUrl={apiUrl} recording={item} />
              <Button onClick={() => navigator(`./${item.id}`)}>
                View Recording
              </Button>
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
};
