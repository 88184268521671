import { Button, Card } from 'antd';
import FeedbackSelector from './FeedbackSelector';
import { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../UserContext';
import { CommunicationModalWithData } from './CommunicationModal';

interface ExpandedLeadProps {
  activitySummary?: {
    task_note: {
      conversation: string;
      note: string;
    };
  };
  isSummaryGood: boolean | null;
  isNoteGood: boolean | null;
  leadId: string;
  apiUrl: string;
  clientId: string;
  batchId: string;
}

const handleCopyToClipboard = (text?: string) => (): void => {
  if (!text) {
    return;
  }
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export const ExpandedLead = (props: ExpandedLeadProps): JSX.Element => {
  const [isSummaryGood, setIsSummaryGood] = useState<boolean | null>(
    props.isSummaryGood,
  );
  const [isNoteGood, setIsNoteGood] = useState<boolean | null>(
    props.isNoteGood,
  );
  const [isCommunicationModalVisible, setIsCommunicationModalVisible] =
    useState<boolean>(false);
  const user = useContext(UserContext);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const postFeedback = async (): Promise<void> => {
        if (user != null) {
          const url = `${props.apiUrl}/api/UpdateFeedbackTask/${props.clientId}/${props.leadId}/${props.batchId}`;
          await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'user-id': user?.userID,
              'user-name': user?.name,
            },
            body: JSON.stringify({
              isActionGood: isNoteGood,
              isSummaryGood,
            }),
          });
        }
      };
      postFeedback().then().catch(console.log);
    }
  }, [isSummaryGood, isNoteGood]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Card
        size="small"
        style={{ width: '75%' }}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>Summary of Recent Conversations</span>
            <FeedbackSelector
              setValue={setIsSummaryGood}
              value={isSummaryGood}
            />
          </div>
        }
      >
        <p>{props.activitySummary?.task_note?.conversation}</p>
      </Card>
      <Card
        size="small"
        style={{ width: '75%' }}
        title={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span>Proposed Note</span>
            <FeedbackSelector setValue={setIsNoteGood} value={isNoteGood} />
          </div>
        }
      >
        <p>{props.activitySummary?.task_note?.note}</p>
        <Button
          onClick={handleCopyToClipboard(
            props.activitySummary?.task_note?.note,
          )}
          type="primary"
        >
          Copy
        </Button>
      </Card>
      <Button
        onClick={() => setIsCommunicationModalVisible(true)}
        style={{ marginTop: '1rem' }}
        type="primary"
      >
        View Communications
      </Button>
      <CommunicationModalWithData
        apiUrl={props.apiUrl}
        batchId={props.batchId}
        clientId={props.clientId}
        closeModal={() => setIsCommunicationModalVisible(false)}
        isModalVisible={isCommunicationModalVisible}
        leadId={props.leadId}
      />
    </div>
  );
};
