import { Typography } from 'antd';

const { Title } = Typography;

interface LogoProps {
  size?: number;
}

export default function Logo(props: LogoProps) {
  const size = props.size || 1;
  return (
    <Title
      level={3}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* <div style={{ height: 24 * size, width: 27 * size }}> */}
      <svg
        fill="none"
        height={40 * size}
        viewBox="0 0 271 386"
        width={28.1 * size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M270.06 210.1V201.32C263.96 198.09 257.87 195.85 251.33 195.27C257.61 199.07 263.66 204.36 270.06 210.1Z"
          fill="black"
        />
        <path
          d="M270.06 210.1V201.32C263.96 198.09 257.87 195.85 251.33 195.27C257.61 199.07 263.66 204.36 270.06 210.1Z"
          fill="url(#paint0_linear_762_2827)"
        />
        <path
          d="M270.06 195.62C269.15 195.55 268.24 195.5 267.31 195.49C267.02 195.49 266.72 195.49 266.43 195.49C265.23 195.49 264.03 195.55 262.82 195.64C265.25 196.54 267.66 197.64 270.06 198.89V195.62Z"
          fill="black"
        />
        <path
          d="M270.06 195.62C269.15 195.55 268.24 195.5 267.31 195.49C267.02 195.49 266.72 195.49 266.43 195.49C265.23 195.49 264.03 195.55 262.82 195.64C265.25 196.54 267.66 197.64 270.06 198.89V195.62Z"
          fill="url(#paint1_linear_762_2827)"
        />
        <path
          d="M256.2 211.17C260.55 216.32 265.08 221.68 270.06 226.76V214.38C269.97 214.3 269.88 214.22 269.79 214.14C259.33 204.72 250.3 196.61 240.01 193.72C245.51 198.53 250.74 204.72 256.19 211.18L256.2 211.17Z"
          fill="black"
        />
        <path
          d="M256.2 211.17C260.55 216.32 265.08 221.68 270.06 226.76V214.38C269.97 214.3 269.88 214.22 269.79 214.14C259.33 204.72 250.3 196.61 240.01 193.72C245.51 198.53 250.74 204.72 256.19 211.18L256.2 211.17Z"
          fill="url(#paint2_linear_762_2827)"
        />
        <path
          d="M242.47 210.1C250.27 222.32 258.66 235.45 270.06 244.86V232.73C263.81 226.72 258.29 220.2 253.03 213.96C244.77 204.18 237.61 195.71 229.55 191.44C234.03 196.89 238.22 203.43 242.47 210.1Z"
          fill="black"
        />
        <path
          d="M242.47 210.1C250.27 222.32 258.66 235.45 270.06 244.86V232.73C263.81 226.72 258.29 220.2 253.03 213.96C244.77 204.18 237.61 195.71 229.55 191.44C234.03 196.89 238.22 203.43 242.47 210.1Z"
          fill="url(#paint3_linear_762_2827)"
        />
        <path
          d="M220.25 189.22C223.57 194.9 226.74 201.48 230.08 208.44C239.9 228.85 250.72 251.3 270.05 260.32V251.5C256.16 241.31 246.49 226.19 238.08 213.03C231.51 202.75 225.97 194.46 220.24 189.23L220.25 189.22Z"
          fill="black"
        />
        <path
          d="M220.25 189.22C223.57 194.9 226.74 201.48 230.08 208.44C239.9 228.85 250.72 251.3 270.05 260.32V251.5C256.16 241.31 246.49 226.19 238.08 213.03C231.51 202.75 225.97 194.46 220.24 189.23L220.25 189.22Z"
          fill="url(#paint4_linear_762_2827)"
        />
        <path
          d="M254.65 87.28C244.23 79.47 227.18 75.56 203.5 75.56C192.13 75.56 181.59 76.27 171.89 77.69C162.18 79.11 154.72 80.53 149.51 81.95V140.78C156.94 134.51 162.98 131.87 168.39 133.23C172.39 134.24 175.43 137.28 177.86 141.99C178.87 143.96 180.2 145.7 181.71 147.25C182.84 148.41 183.89 149.8 184.86 151.39C186.1 153.42 187.59 155.24 189.31 156.8C190.5 157.87 191.64 159.15 192.73 160.64C194.04 162.43 195.61 163.96 197.35 165.26C198.51 166.12 199.61 167.13 200.66 168.28C202.47 170.24 204.49 171.92 206.69 173.32C207.93 174.11 209.11 175.03 210.26 176.07C211.99 177.64 213.91 178.93 215.97 179.91C217.45 180.61 218.89 181.47 220.28 182.46C222.05 183.72 223.97 184.73 226.01 185.36C228.11 186 230.12 186.89 232.08 187.99C233.62 188.85 235.27 189.45 236.98 189.72C239.42 190.11 241.78 190.77 244.08 191.66C245.9 192.37 247.8 192.79 249.73 192.88C252.52 193 255.23 193.42 257.88 194.09C259.65 194.54 261.46 194.71 263.27 194.58C265.58 194.43 267.83 194.45 270.06 194.61V132.47H270.28V132.39C270.28 110.14 265.06 95.09 254.65 87.28Z"
          fill="black"
        />
        <path
          d="M254.65 87.28C244.23 79.47 227.18 75.56 203.5 75.56C192.13 75.56 181.59 76.27 171.89 77.69C162.18 79.11 154.72 80.53 149.51 81.95V140.78C156.94 134.51 162.98 131.87 168.39 133.23C172.39 134.24 175.43 137.28 177.86 141.99C178.87 143.96 180.2 145.7 181.71 147.25C182.84 148.41 183.89 149.8 184.86 151.39C186.1 153.42 187.59 155.24 189.31 156.8C190.5 157.87 191.64 159.15 192.73 160.64C194.04 162.43 195.61 163.96 197.35 165.26C198.51 166.12 199.61 167.13 200.66 168.28C202.47 170.24 204.49 171.92 206.69 173.32C207.93 174.11 209.11 175.03 210.26 176.07C211.99 177.64 213.91 178.93 215.97 179.91C217.45 180.61 218.89 181.47 220.28 182.46C222.05 183.72 223.97 184.73 226.01 185.36C228.11 186 230.12 186.89 232.08 187.99C233.62 188.85 235.27 189.45 236.98 189.72C239.42 190.11 241.78 190.77 244.08 191.66C245.9 192.37 247.8 192.79 249.73 192.88C252.52 193 255.23 193.42 257.88 194.09C259.65 194.54 261.46 194.71 263.27 194.58C265.58 194.43 267.83 194.45 270.06 194.61V132.47H270.28V132.39C270.28 110.14 265.06 95.09 254.65 87.28Z"
          fill="url(#paint5_linear_762_2827)"
        />
        <path
          d="M202.44 214.69C201.7 211.93 200.97 209.2 200.26 206.51L199.38 203.19C199.4 203.27 199.4 203.32 199.42 203.37C199.4 203.28 199.38 203.19 199.38 203.19C198.76 200.85 198.15 198.54 197.53 196.26C197.49 196.28 197.46 196.31 197.42 196.33C197.61 197.33 197.79 198.34 197.98 199.35C198.24 200.76 198.51 202.19 198.78 203.63L199.44 207.12C199.98 209.96 200.53 212.84 201.12 215.73L201.59 218.05C207.01 244.49 214.66 271.58 229.96 281.46C235.05 284.74 240.79 286.02 247.38 285.33C236.83 282.29 227.84 274.7 220.51 262.58C212.69 249.66 207.44 232.98 203.04 216.91L202.44 214.68V214.69ZM199.47 203.55C199.48 203.62 199.49 203.63 199.47 203.55V203.55Z"
          fill="black"
        />
        <path
          d="M202.44 214.69C201.7 211.93 200.97 209.2 200.26 206.51L199.38 203.19C199.4 203.27 199.4 203.32 199.42 203.37C199.4 203.28 199.38 203.19 199.38 203.19C198.76 200.85 198.15 198.54 197.53 196.26C197.49 196.28 197.46 196.31 197.42 196.33C197.61 197.33 197.79 198.34 197.98 199.35C198.24 200.76 198.51 202.19 198.78 203.63L199.44 207.12C199.98 209.96 200.53 212.84 201.12 215.73L201.59 218.05C207.01 244.49 214.66 271.58 229.96 281.46C235.05 284.74 240.79 286.02 247.38 285.33C236.83 282.29 227.84 274.7 220.51 262.58C212.69 249.66 207.44 232.98 203.04 216.91L202.44 214.68V214.69ZM199.47 203.55C199.48 203.62 199.49 203.63 199.47 203.55V203.55Z"
          fill="url(#paint6_linear_762_2827)"
        />
        <path
          d="M212.37 189.21C214.49 194.45 216.54 200.16 218.68 206.12C229.29 235.67 241.2 268.82 270.06 271.11V267.3C247.02 258.13 234.74 232.64 224.46 211.26C220.57 203.17 216.79 195.31 212.77 189.12C212.64 189.15 212.51 189.17 212.38 189.2L212.37 189.21Z"
          fill="black"
        />
        <path
          d="M212.37 189.21C214.49 194.45 216.54 200.16 218.68 206.12C229.29 235.67 241.2 268.82 270.06 271.11V267.3C247.02 258.13 234.74 232.64 224.46 211.26C220.57 203.17 216.79 195.31 212.77 189.12C212.64 189.15 212.51 189.17 212.38 189.2L212.37 189.21Z"
          fill="url(#paint7_linear_762_2827)"
        />
        <path
          d="M192.77 221.91C192.16 218.98 191.58 216.05 191.01 213.12C190.95 213.16 190.88 213.21 190.82 213.25C191.23 216.28 191.66 219.32 192.12 222.35L192.48 224.67C193.01 228.08 193.58 231.47 194.19 234.82L194.4 235.96C198.86 259.89 205.68 281.31 218.23 289.4C222.08 291.88 226.39 293.01 231.26 292.8C229.07 291.96 226.97 290.89 224.95 289.59C207.4 278.26 199.11 251.75 193.25 224.15L192.78 221.91H192.77Z"
          fill="black"
        />
        <path
          d="M192.77 221.91C192.16 218.98 191.58 216.05 191.01 213.12C190.95 213.16 190.88 213.21 190.82 213.25C191.23 216.28 191.66 219.32 192.12 222.35L192.48 224.67C193.01 228.08 193.58 231.47 194.19 234.82L194.4 235.96C198.86 259.89 205.68 281.31 218.23 289.4C222.08 291.88 226.39 293.01 231.26 292.8C229.07 291.96 226.97 290.89 224.95 289.59C207.4 278.26 199.11 251.75 193.25 224.15L192.78 221.91H192.77Z"
          fill="url(#paint8_linear_762_2827)"
        />
        <path
          d="M205.45 191.69C205.31 191.75 205.18 191.82 205.04 191.89C206.04 195.53 207.03 199.29 208.04 203.11C217.89 240.57 229.03 282.92 264.05 277.87C234.54 271.99 222.09 237.32 211.82 208.69C209.69 202.77 207.61 196.95 205.46 191.69H205.45Z"
          fill="black"
        />
        <path
          d="M205.45 191.69C205.31 191.75 205.18 191.82 205.04 191.89C206.04 195.53 207.03 199.29 208.04 203.11C217.89 240.57 229.03 282.92 264.05 277.87C234.54 271.99 222.09 237.32 211.82 208.69C209.69 202.77 207.61 196.95 205.46 191.69H205.45Z"
          fill="url(#paint9_linear_762_2827)"
        />
        <path
          d="M179.57 208.02C168.52 214.81 158.68 219.9 149.51 223.56V228.73C158.93 226.94 169.08 222.9 180.63 215.99C180.27 213.33 179.92 210.67 179.58 208.02H179.57Z"
          fill="black"
        />
        <path
          d="M179.57 208.02C168.52 214.81 158.68 219.9 149.51 223.56V228.73C158.93 226.94 169.08 222.9 180.63 215.99C180.27 213.33 179.92 210.67 179.58 208.02H179.57Z"
          fill="url(#paint10_linear_762_2827)"
        />
        <path
          d="M182.44 228.35C181.97 225.38 181.52 222.38 181.09 219.38C169.44 226.18 159.11 230.14 149.5 231.88V236.56C159.05 237.6 169.63 235.42 182.43 228.35H182.44Z"
          fill="black"
        />
        <path
          d="M182.44 228.35C181.97 225.38 181.52 222.38 181.09 219.38C169.44 226.18 159.11 230.14 149.5 231.88V236.56C159.05 237.6 169.63 235.42 182.43 228.35H182.44Z"
          fill="url(#paint11_linear_762_2827)"
        />
        <path
          d="M190.32 209.49C190.33 209.57 190.34 209.65 190.35 209.73C190.35 209.73 190.35 209.73 190.36 209.73C190.35 209.65 190.33 209.57 190.32 209.5V209.49Z"
          fill="black"
        />
        <path
          d="M190.32 209.49C190.33 209.57 190.34 209.65 190.35 209.73C190.35 209.73 190.35 209.73 190.36 209.73C190.35 209.65 190.33 209.57 190.32 209.5V209.49Z"
          fill="url(#paint12_linear_762_2827)"
        />
        <path
          d="M184.57 240.46C183.94 237.2 183.35 233.9 182.8 230.57C170.63 237.16 159.75 239.79 149.51 238.73V242.09C159.16 247.05 170.14 247.67 184.57 240.47V240.46Z"
          fill="black"
        />
        <path
          d="M184.57 240.46C183.94 237.2 183.35 233.9 182.8 230.57C170.63 237.16 159.75 239.79 149.51 238.73V242.09C159.16 247.05 170.14 247.67 184.57 240.47V240.46Z"
          fill="url(#paint13_linear_762_2827)"
        />
        <path
          d="M270.06 292.96C262.5 295.07 254.06 297.71 244.56 300.94C232.37 305.07 221.63 304.24 212.65 298.44C197.58 288.73 189.77 266.72 184.78 241.55C177.5 245.15 170.79 246.91 164.5 246.91C161.94 246.91 159.45 246.62 157.02 246.04C154.41 245.42 151.92 244.46 149.51 243.25V328.83C149.51 350.62 154.84 365.54 165.49 373.58C176.15 381.63 193.07 385.66 216.28 385.66C227.65 385.66 238.18 384.95 247.89 383.53C257.59 382.11 265.05 380.69 270.27 379.27V313.36H270.05V292.96H270.06Z"
          fill="black"
        />
        <path
          d="M270.06 292.96C262.5 295.07 254.06 297.71 244.56 300.94C232.37 305.07 221.63 304.24 212.65 298.44C197.58 288.73 189.77 266.72 184.78 241.55C177.5 245.15 170.79 246.91 164.5 246.91C161.94 246.91 159.45 246.62 157.02 246.04C154.41 245.42 151.92 244.46 149.51 243.25V328.83C149.51 350.62 154.84 365.54 165.49 373.58C176.15 381.63 193.07 385.66 216.28 385.66C227.65 385.66 238.18 384.95 247.89 383.53C257.59 382.11 265.05 380.69 270.27 379.27V313.36H270.05V292.96H270.06Z"
          fill="url(#paint14_linear_762_2827)"
        />
        <path
          d="M173.33 162.86C166.58 165.85 158.42 172.29 149.51 180.35V182.67C158.06 175.87 166.25 169.96 173.91 165.99C173.72 164.92 173.53 163.88 173.33 162.87V162.86Z"
          fill="black"
        />
        <path
          d="M173.33 162.86C166.58 165.85 158.42 172.29 149.51 180.35V182.67C158.06 175.87 166.25 169.96 173.91 165.99C173.72 164.92 173.53 163.88 173.33 162.87V162.86Z"
          fill="url(#paint15_linear_762_2827)"
        />
        <path
          d="M178.14 196.53C177.39 197 176.64 197.46 175.9 197.93C167.01 203.49 158.22 208.97 149.51 213.93V218.96C158.46 215.26 168.12 210.16 178.99 203.39C178.7 201.09 178.42 198.8 178.14 196.53Z"
          fill="black"
        />
        <path
          d="M178.14 196.53C177.39 197 176.64 197.46 175.9 197.93C167.01 203.49 158.22 208.97 149.51 213.93V218.96C158.46 215.26 168.12 210.16 178.99 203.39C178.7 201.09 178.42 198.8 178.14 196.53Z"
          fill="url(#paint16_linear_762_2827)"
        />
        <path
          d="M177.42 190.72C177.18 188.81 176.94 186.92 176.71 185.05C167.99 190.37 159 196.73 149.65 203.34C149.61 203.37 149.56 203.4 149.51 203.44V207.83C157.23 203.34 165.1 198.43 173.18 193.37C174.58 192.49 176 191.61 177.43 190.72H177.42Z"
          fill="black"
        />
        <path
          d="M177.42 190.72C177.18 188.81 176.94 186.92 176.71 185.05C167.99 190.37 159 196.73 149.65 203.34C149.61 203.37 149.56 203.4 149.51 203.44V207.83C157.23 203.34 165.1 198.43 173.18 193.37C174.58 192.49 176 191.61 177.43 190.72H177.42Z"
          fill="url(#paint17_linear_762_2827)"
        />
        <path
          d="M151.29 153.52C156.95 148.75 161.97 145.5 166.53 143.99C166.19 143.71 165.96 143.63 165.88 143.61C165.5 143.51 162.05 143.06 151.29 153.53V153.52Z"
          fill="black"
        />
        <path
          d="M151.29 153.52C156.95 148.75 161.97 145.5 166.53 143.99C166.19 143.71 165.96 143.63 165.88 143.61C165.5 143.51 162.05 143.06 151.29 153.53V153.52Z"
          fill="url(#paint18_linear_762_2827)"
        />
        <path
          d="M170.89 152.75C165.75 153.66 158.11 159.74 149.51 168.07V169.04C157.45 162.27 164.77 157.07 171.39 154.43C171.22 153.83 171.06 153.27 170.9 152.74L170.89 152.75Z"
          fill="black"
        />
        <path
          d="M170.89 152.75C165.75 153.66 158.11 159.74 149.51 168.07V169.04C157.45 162.27 164.77 157.07 171.39 154.43C171.22 153.83 171.06 153.27 170.9 152.74L170.89 152.75Z"
          fill="url(#paint19_linear_762_2827)"
        />
        <path
          d="M175.16 173.75C167.28 178.15 158.62 184.75 149.51 192.19V195.65C158.54 189.28 167.25 183.23 175.79 178.17C175.58 176.67 175.37 175.2 175.16 173.76V173.75Z"
          fill="black"
        />
        <path
          d="M175.16 173.75C167.28 178.15 158.62 184.75 149.51 192.19V195.65C158.54 189.28 167.25 183.23 175.79 178.17C175.58 176.67 175.37 175.2 175.16 173.76V173.75Z"
          fill="url(#paint20_linear_762_2827)"
        />
        <path
          d="M30.38 241.45C36.14 246.26 42.65 247.79 49.68 246.9C44.15 245.73 38.78 243.26 33.61 239.22C27.62 234.54 22.68 230.54 18.32 227.02C11.24 221.29 5.41 216.85 0 213.22V214.32C3.21 217.07 6.41 220.03 10.02 223.38C15.49 228.45 21.69 234.19 30.37 241.44L30.38 241.45Z"
          fill="black"
        />
        <path
          d="M30.38 241.45C36.14 246.26 42.65 247.79 49.68 246.9C44.15 245.73 38.78 243.26 33.61 239.22C27.62 234.54 22.68 230.54 18.32 227.02C11.24 221.29 5.41 216.85 0 213.22V214.32C3.21 217.07 6.41 220.03 10.02 223.38C15.49 228.45 21.69 234.19 30.37 241.44L30.38 241.45Z"
          fill="url(#paint21_linear_762_2827)"
        />
        <path
          d="M38.06 233.29C45.06 238.76 52.83 241 61.79 240.02C62 239.81 62.22 239.6 62.44 239.4C55.23 238.03 48.48 235.03 42.11 230.37L37.21 226.78C20.08 214.21 11.25 207.74 0 202.92V204.15C7.34 208.61 13.77 213.8 22.87 221.17C27.2 224.68 32.12 228.65 38.06 233.3V233.29Z"
          fill="black"
        />
        <path
          d="M38.06 233.29C45.06 238.76 52.83 241 61.79 240.02C62 239.81 62.22 239.6 62.44 239.4C55.23 238.03 48.48 235.03 42.11 230.37L37.21 226.78C20.08 214.21 11.25 207.74 0 202.92V204.15C7.34 208.61 13.77 213.8 22.87 221.17C27.2 224.68 32.12 228.65 38.06 233.3V233.29Z"
          fill="url(#paint22_linear_762_2827)"
        />
        <path
          d="M22.73 249.65C27.03 253.49 31.83 254.79 36.75 254.49C32.6 253.24 28.72 251.11 25.09 248.07C16.24 240.68 9.96 234.86 4.41 229.72C2.87 228.3 1.43 226.96 0.01 225.67V226.89C0.16 227.05 0.31 227.21 0.46 227.38C6.16 233.59 12.63 240.63 22.73 249.65Z"
          fill="black"
        />
        <path
          d="M22.73 249.65C27.03 253.49 31.83 254.79 36.75 254.49C32.6 253.24 28.72 251.11 25.09 248.07C16.24 240.68 9.96 234.86 4.41 229.72C2.87 228.3 1.43 226.96 0.01 225.67V226.89C0.16 227.05 0.31 227.21 0.46 227.38C6.16 233.59 12.63 240.63 22.73 249.65Z"
          fill="url(#paint23_linear_762_2827)"
        />
        <path
          d="M15.1 257.86C17.9 260.53 21.03 261.91 24.63 262.05C21.76 260.85 19.06 259.14 16.54 256.88C9.67 250.74 4.45 245.52 0.01 240.85V241.67C4 246.49 8.77 251.81 15.1 257.86Z"
          fill="black"
        />
        <path
          d="M15.1 257.86C17.9 260.53 21.03 261.91 24.63 262.05C21.76 260.85 19.06 259.14 16.54 256.88C9.67 250.74 4.45 245.52 0.01 240.85V241.67C4 246.49 8.77 251.81 15.1 257.86Z"
          fill="url(#paint24_linear_762_2827)"
        />
        <path
          d="M45.77 225.15C52.98 230.43 60.48 233.05 68.18 233.66C68.95 232.87 69.71 232.07 70.48 231.26C63.87 229.22 57.25 226.04 50.59 221.47C26.17 204.72 16.33 198.82 0.01 194.97V196.01C13.07 201.17 22.19 207.85 40.88 221.56L45.77 225.15Z"
          fill="black"
        />
        <path
          d="M45.77 225.15C52.98 230.43 60.48 233.05 68.18 233.66C68.95 232.87 69.71 232.07 70.48 231.26C63.87 229.22 57.25 226.04 50.59 221.47C26.17 204.72 16.33 198.82 0.01 194.97V196.01C13.07 201.17 22.19 207.85 40.88 221.56L45.77 225.15Z"
          fill="url(#paint25_linear_762_2827)"
        />
        <path
          d="M105.15 11.72C94.72 3.91 77.68 0 54 0C42.63 0 32.09 0.71 22.39 2.13C12.68 3.55 5.22 4.97 0.01 6.39V174.95C1.29 174.68 2.54 174.42 3.77 174.16C18.87 171 29.78 168.72 41.58 169.7C53.69 170.71 66.59 175.21 84.7 184.76C90.7 187.92 96.11 191.78 101.08 195.98C103.82 192.65 106.51 189.34 109.14 186.12C113.19 181.15 116.99 176.51 120.56 172.19V56.91H120.78V56.83C120.78 34.58 115.56 19.53 105.15 11.72Z"
          fill="black"
        />
        <path
          d="M105.15 11.72C94.72 3.91 77.68 0 54 0C42.63 0 32.09 0.71 22.39 2.13C12.68 3.55 5.22 4.97 0.01 6.39V174.95C1.29 174.68 2.54 174.42 3.77 174.16C18.87 171 29.78 168.72 41.58 169.7C53.69 170.71 66.59 175.21 84.7 184.76C90.7 187.92 96.11 191.78 101.08 195.98C103.82 192.65 106.51 189.34 109.14 186.12C113.19 181.15 116.99 176.51 120.56 172.19V56.91H120.78V56.83C120.78 34.58 115.56 19.53 105.15 11.72Z"
          fill="url(#paint26_linear_762_2827)"
        />
        <path
          d="M74.56 226.9C75.75 225.61 76.94 224.3 78.13 222.97C71.91 220.22 65.57 216.76 59.03 212.56C30.89 194.48 20.43 190.45 0 188.6V189.49C17.44 193.44 27.84 199.44 53.49 217.03C60.55 221.87 67.55 225.06 74.55 226.9H74.56Z"
          fill="black"
        />
        <path
          d="M74.56 226.9C75.75 225.61 76.94 224.3 78.13 222.97C71.91 220.22 65.57 216.76 59.03 212.56C30.89 194.48 20.43 190.45 0 188.6V189.49C17.44 193.44 27.84 199.44 53.49 217.03C60.55 221.87 67.55 225.06 74.55 226.9H74.56Z"
          fill="url(#paint27_linear_762_2827)"
        />
        <path
          d="M120.56 230.9C119.91 230.87 119.26 230.86 118.61 230.9C116.92 231 115.23 231.05 113.55 231.04C111.99 231.04 110.42 231.21 108.9 231.59C106.97 232.07 105.04 232.49 103.12 232.84C102.05 233.03 101 233.33 99.99 233.76C98.66 234.33 97.33 234.87 96.01 235.37C94.99 235.75 93.99 236.11 92.99 236.45C91.73 236.87 90.49 237.39 89.32 238.04C86.93 239.36 84.54 240.57 82.16 241.66C81.28 242.06 80.43 242.53 79.62 243.08C78.56 243.79 77.49 244.49 76.43 245.15C74.82 246.16 73.23 247.09 71.67 247.95C70.89 248.38 70.13 248.86 69.42 249.41C69.18 249.59 68.94 249.78 68.71 249.95C66.11 251.89 63.57 253.62 61.1 255.16C60.44 255.57 59.81 256.02 59.2 256.52C47.86 265.9 36.93 272.07 27 272.71C19.75 273.18 13.36 270.81 7.99 265.68C5.07 262.89 2.48 260.26 0.13 257.76C0.83 277.12 6.12 290.55 16.03 298.03C26.69 306.08 43.61 310.11 66.82 310.11C78.19 310.11 88.72 309.4 98.43 307.98C108.13 306.56 115.59 305.14 120.81 303.72V237.81H120.59V230.91L120.56 230.9Z"
          fill="black"
        />
        <path
          d="M120.56 230.9C119.91 230.87 119.26 230.86 118.61 230.9C116.92 231 115.23 231.05 113.55 231.04C111.99 231.04 110.42 231.21 108.9 231.59C106.97 232.07 105.04 232.49 103.12 232.84C102.05 233.03 101 233.33 99.99 233.76C98.66 234.33 97.33 234.87 96.01 235.37C94.99 235.75 93.99 236.11 92.99 236.45C91.73 236.87 90.49 237.39 89.32 238.04C86.93 239.36 84.54 240.57 82.16 241.66C81.28 242.06 80.43 242.53 79.62 243.08C78.56 243.79 77.49 244.49 76.43 245.15C74.82 246.16 73.23 247.09 71.67 247.95C70.89 248.38 70.13 248.86 69.42 249.41C69.18 249.59 68.94 249.78 68.71 249.95C66.11 251.89 63.57 253.62 61.1 255.16C60.44 255.57 59.81 256.02 59.2 256.52C47.86 265.9 36.93 272.07 27 272.71C19.75 273.18 13.36 270.81 7.99 265.68C5.07 262.89 2.48 260.26 0.13 257.76C0.83 277.12 6.12 290.55 16.03 298.03C26.69 306.08 43.61 310.11 66.82 310.11C78.19 310.11 88.72 309.4 98.43 307.98C108.13 306.56 115.59 305.14 120.81 303.72V237.81H120.59V230.91L120.56 230.9Z"
          fill="url(#paint28_linear_762_2827)"
        />
        <path
          d="M76.86 192.81C83.15 196.35 88.95 200.23 94.35 204.13C96.39 201.68 98.41 199.24 100.41 196.81C95.49 192.65 90.14 188.84 84.22 185.72C47.16 166.18 34.52 168.83 3.98 175.22C2.68 175.49 1.36 175.77 0.01 176.05V177.18C29.27 173.58 41.16 172.7 76.86 192.82V192.81Z"
          fill="black"
        />
        <path
          d="M76.86 192.81C83.15 196.35 88.95 200.23 94.35 204.13C96.39 201.68 98.41 199.24 100.41 196.81C95.49 192.65 90.14 188.84 84.22 185.72C47.16 166.18 34.52 168.83 3.98 175.22C2.68 175.49 1.36 175.77 0.01 176.05V177.18C29.27 173.58 41.16 172.7 76.86 192.82V192.81Z"
          fill="url(#paint29_linear_762_2827)"
        />
        <path
          d="M69.05 200.87C70.76 201.9 72.44 202.91 74.1 203.92C78.84 206.78 83.35 209.5 87.69 212.04C89.47 209.96 91.23 207.86 92.99 205.77C87.68 201.94 82.06 198.19 75.85 194.69C40.64 174.85 28.97 175.77 0.01 179.33V180.26C25.35 179.59 36.57 181.33 69.05 200.87Z"
          fill="black"
        />
        <path
          d="M69.05 200.87C70.76 201.9 72.44 202.91 74.1 203.92C78.84 206.78 83.35 209.5 87.69 212.04C89.47 209.96 91.23 207.86 92.99 205.77C87.68 201.94 82.06 198.19 75.85 194.69C40.64 174.85 28.97 175.77 0.01 179.33V180.26C25.35 179.59 36.57 181.33 69.05 200.87Z"
          fill="url(#paint30_linear_762_2827)"
        />
        <path
          d="M81.08 219.66C82.58 217.96 84.09 216.23 85.58 214.49C81.37 212.03 77.03 209.41 72.5 206.67C70.85 205.67 69.16 204.65 67.46 203.63C35.73 184.54 24.73 182.82 0.01 183.46V184.32C21 186.18 32.23 190.29 61.26 208.94C68.13 213.35 74.69 216.91 81.08 219.66Z"
          fill="black"
        />
        <path
          d="M81.08 219.66C82.58 217.96 84.09 216.23 85.58 214.49C81.37 212.03 77.03 209.41 72.5 206.67C70.85 205.67 69.16 204.65 67.46 203.63C35.73 184.54 24.73 182.82 0.01 183.46V184.32C21 186.18 32.23 190.29 61.26 208.94C68.13 213.35 74.69 216.91 81.08 219.66Z"
          fill="url(#paint31_linear_762_2827)"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint1_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint2_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint3_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint4_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint5_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint6_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint7_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint8_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint9_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint10_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint11_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint12_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint13_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint14_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint15_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint16_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint17_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint18_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint19_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint20_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint21_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint22_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint23_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint24_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint25_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint26_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint27_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint28_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint29_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint30_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint31_linear_762_2827"
            x1="1.38011e-05"
            x2="96.8803"
            y1="-64.5"
            y2="432.335"
          >
            <stop stopColor="#16FEBF" />
            <stop offset="0.515" stopColor="#338FF0" />
            <stop offset="1" stopColor="#A328FF" />
          </linearGradient>
        </defs>
      </svg>

      {/* </div> */}
      <div
        style={{
          lineHeight: 1,
          background:
            'linear-gradient(230.52deg, #16FEBF 0.37%, #338FF0 48.45%, #A328FF 93.73%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          WebkitBoxDecorationBreak: 'clone',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontFamily: 'Baloo Paaji 2',
            fontWeight: 800,
            fontSize: 29 * size,
            marginLeft: 5,
          }}
        >
          house
        </span>
        <span
          style={{
            fontFamily: 'Alef',
            fontWeight: 400,
            fontSize: 29 * size,
          }}
        >
          whisper
        </span>
      </div>
    </Title>
  );
}
