import {
  Button,
  CollapsePanelProps,
  ConfigProvider,
  Flex,
  GlobalToken,
  theme,
  Typography,
} from 'antd';
import { Lead, Task } from '../../types';
import TaskIcon from './task-icon';
import { CollapsableTaskContent, TaskContent } from './lead-task-content';
import { useState } from 'react';
import { SubTaskTitle, TaskTitle } from './lead-task-title';
import { crmLink } from '../../utils';
// import { TaskTitle } from './task-title';

const { Text } = Typography;

interface LeadTaskProps {
  lead: Lead & { tasks: Task[]; score: number };
  toggleTask: (task: Task) => Promise<void>;
  pushToSend: (task: Task, message?: string) => Promise<void>;
  messages: Record<string, string>;
  setMessage: (task: Task, message: string) => void;
  token: GlobalToken;
  activeTask: string | null;
  activeTaskAction: string | null;
}

export default function LeadTask(props: LeadTaskProps) {
  const {
    lead,
    toggleTask,
    pushToSend,
    messages,
    setMessage,
    token,
    activeTask,
    activeTaskAction,
  } = props;

  return {
    header: undefined,
    key: `${lead.id}`,
    headerClass: 'header',
    task: lead.tasks[0],
    initialActive: lead.tasks.length > 1,
    // collapsible: lead.tasks.length > 1 ? undefined : 'disabled',
    label: (
      <Flex align="center" justify="space-between">
        <Flex flex={1} gap="small" style={{ maxWidth: '80%' }}>
          <div
            style={{
              height: '50px',
              minWidth: '50px',
              backgroundColor: 'white',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              flexDirection: 'column',
              lineHeight: 1,
            }}
          >
            <span
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '8px',
                // color: '#28638A',
                fontWeight: '600',
                background:
                  'linear-gradient(257.82deg, #D53776 77.33%, #D435BA 83.74%, #D433E4 87.86%, #74CEE1 96.3%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                WebkitBoxDecorationBreak: 'clone',
              }}
            >
              AI SCORE
            </span>
            <span
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '20px',
                // color: '#28638A',
                fontWeight: '600',
                background:
                  'linear-gradient(257.82deg, #D53776 77.33%, #D435BA 83.74%, #D433E4 87.86%, #74CEE1 96.3%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                WebkitBoxDecorationBreak: 'clone',
              }}
            >
              {Math.round(lead.score * 100)}
            </span>
            {/* <img
                  alt="Profile Picture"
                  src={avatars[lead.id] || DEFAULT_AVATAR}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderRadius: '50%',
                  }}
                /> */}
          </div>
          <Flex
            align="flex-start"
            flex={1}
            gap={0}
            justify="flex-start"
            vertical
            wrap="wrap"
          >
            <Text
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '16px',
                color: '#28638A',
                fontWeight: 'bold',
              }}
            >
              {lead.first_name} {lead.last_name}
            </Text>
            {/* <Text
                  style={{
                    fontFamily: 'Roboto Slab',
                    fontSize: '14px',
                    // color: '#28638A',
                    fontWeight: '600',
                    backgroundImage:
                      'linear-gradient(257.82deg, #D53776 77.33%, #D435BA 83.74%, #D433E4 87.86%, #74CEE1 96.3%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    WebkitBoxDecorationBreak: 'clone',
                  }}
                >
                  AI Rank: {Math.round(Math.random() * 100)}%
                </Text> */}
            <div style={{ maxWidth: '100%' }}>
              {lead.tasks.length >= 1 && (
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                    color: '#28638A',
                  }}
                >
                  {lead.tasks.length > 1 ? (
                    `${lead.tasks.length} tasks waiting`
                  ) : (
                    <TaskTitle task={lead.tasks[0]} />
                  )}
                </Text>
              )}
            </div>
          </Flex>
        </Flex>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            fontSize: '2.2em',
            color: '#28638A',
          }}
        >
          {lead.tasks.length > 1 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                border: '2.5px solid',
                borderRadius: '50%',
                fontSize: '0.6em',
                height: '1.5em',
                width: '1.5em',
              }}
            >
              <span>{lead.tasks.length}</span>
            </div>
          ) : (
            <TaskIcon
              pushToSend={() =>
                pushToSend(lead.tasks[0], messages[lead.tasks[0].id])
              }
              task={lead.tasks[0]}
              toggleTask={toggleTask}
            />
          )}
        </div>
      </Flex>
    ),
    children:
      lead.tasks.length > 1 ? (
        <div style={{ marginLeft: '0px' }}>
          {lead.tasks.map(task => (
            <div
              key={task.id}
              style={{
                borderTop: '0.3px solid #28638A',
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <CollapsableTaskContent
                open={activeTask === task.id}
                pushToSend={pushToSend}
                task={task}
                taskAction={activeTask === task.id ? activeTaskAction : null}
                toggleTask={toggleTask}
              />
            </div>
          ))}
          <Flex
            justify="flex-end"
            style={{
              borderTop: '0.3px solid #28638A',
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <a
              href={crmLink(lead)}
              onAuxClick={e => {
                e.stopPropagation();
              }}
              onClick={e => {
                e.stopPropagation();
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorPrimary: '#28638A',
                    },
                  },
                }}
              >
                <Button
                  ghost
                  shape="round"
                  style={{ fontFamily: 'Roboto Slab', fontWeight: 400 }}
                  type="primary"
                >
                  CRM
                </Button>
              </ConfigProvider>
            </a>
          </Flex>
        </div>
      ) : (
        <TaskContent
          message={messages[lead.tasks[0].id]}
          setMessage={(message: string) => setMessage(lead.tasks[0], message)}
          task={lead.tasks[0]}
          taskAction={activeTask === lead.tasks[0].id ? activeTaskAction : null}
        />
      ),
    style: {
      marginBottom: 12,
      background: '#F1F4F9',
      borderRadius: token.borderRadiusLG,
      border: 'none',
      textAlign: 'left',
    },
    showArrow: false,
  };
}
