import { Alert } from 'antd';
import { useEffect, useState } from 'react';

interface AlertProps {
  message?: string;
  type?: 'error' | 'warning' | 'info' | 'success';
}

// Create React component to expose errors as mui alerts with a close button
export default function CustomAlert(props: AlertProps) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (props.message) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [props.message]);
  return isOpen ? (
    <Alert
      banner
      closable
      description={props.message}
      //   message="Error"
      onClose={() => setIsOpen(false)}
      style={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}
      type={props.type || 'error'}
    />
  ) : undefined;
}
