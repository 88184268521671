import { RedoOutlined, SendOutlined } from '@ant-design/icons';
import { Button, GlobalToken, Input, Spin, Typography } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CallFollowUpRawTask, Task } from '../../../types';
import PushToSendLeadBio from '../pushToSend/pts-lead-bio';
import PushToSendLeadContent from '../pushToSend/push-to-send-lead-content';
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

interface PushToSendEmailTaskProps {
  task: Task;
  token: GlobalToken;
  isActive: boolean;
  apiUrl: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  agentId: string | undefined;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onTaskRemove: (taskId: string) => void;
  isEditing: boolean;
}

function convertToMailTo(body: string) {
  return body
    ?.replace(/<p><br><\/p>/g, '%0D%0A')
    .replace(/<p>/g, '')
    .replace(/<\/p>/g, '%0D%0A')
    .replace(/<br>/g, '%0D%0A')
    .replace(/<strong>/g, '')
    .replace(/<\/strong>/g, '');
}

const PushToSendEmailTask: React.FC<PushToSendEmailTaskProps> = ({
  task,
  token,
  isActive,
  apiUrl,
  setAlertMessage,
  agentId,
  setError,
  onTaskRemove,
  isEditing,
  setLoading,
}) => {
  const [isEditingState, setIsEditingState] = useState(isEditing);
  const [emailMessage, setEmailMessage] = useState(
    task.talking_points.email?.replace(/\n/g, '<br/>'),
  );

  const handleEmailMessageChange = (value: string) => {
    setEmailMessage(value);
  };

  const [emailSubject, setEmailSubject] = useState(
    task.talking_points.email_subject,
  );
  const [rephraseLoading, setRephraseLoading] = useState(false);

  useLayoutEffect(() => {
    setIsEditingState(isEditing);
  }, [isEditing]);

  const handleTextClick = () => {
    setIsEditingState(true);
  };

  const handleSubjectTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setEmailSubject(e.target.value);
  };

  const handleTextBlur = () => {
    const email = emailMessage
      ?.replace(/<p><br><\/p>/g, '<br>')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>');
    setEmailMessage(email);
    setIsEditingState(false);
  };

  const handleRegenerate = async (): Promise<void> => {
    try {
      setRephraseLoading(true);

      const response = await fetch(`${apiUrl}/api/rephrase/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: emailMessage,
          recordingId: (task.raw_task as CallFollowUpRawTask).recording_id, // Probably should just rework the types to avoid this
        }),
      });
      const data = await response.json();

      setEmailMessage(data.message);
    } catch (error) {
      console.error('Error regenerating text:', error);
    } finally {
      setRephraseLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      const res = await fetch(`${apiUrl}/api/pts/send_email/${task.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          checked_off_by: agentId, // TODO come back to add user which is based on src/UserContext.tsx looks like for auth
          message: emailMessage,
          subject: emailSubject,
        }),
      });

      if (!res.ok) {
        throw new Error('Failed to send message');
      }

      onTaskRemove(task.id);
      setAlertMessage(
        `Follow-up sent to ${task.lead.first_name} ${task.lead.last_name}`,
      );
    } catch (error) {
      setError(
        'Failed to send message, please contact aiassistant@housewhisper.ai',
      );
      return;
    } finally {
      setLoading(false);
    }
  };

  const mailtoLink = `mailto:${task.lead.email}?subject=${emailSubject ?? ''}&body=${convertToMailTo(emailMessage ?? '')}`;

  return (
    <div>
      <Title
        level={4}
        style={{
          fontFamily: 'Roboto Slab',
          fontWeight: '400',
          marginTop: '10px',
          textAlign: 'left',
          color: '#28638A',
        }}
      >
        {task.title}
      </Title>
      <PushToSendLeadContent task={task} token={token} />
      {rephraseLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" tip="Rephrasing Email Body">
            <div className="ant-spin-content" />
          </Spin>
        </div>
      ) : (
        <div>
          <div
            style={{
              marginTop: '10px',
              textAlign: 'left',
            }}
          >
            <Title
              level={5}
              style={{
                fontFamily: 'Roboto Slab',
                fontWeight: '400',
                marginTop: '10px',
                textAlign: 'left',
                color: '#28638A',
              }}
            >
              Subject
            </Title>

            <TextArea
              autoSize
              onBlur={handleTextBlur}
              onChange={handleSubjectTextChange}
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '16px',
                color: '#28638A',
              }}
              value={emailSubject}
            />
          </div>
          <div
            style={{
              marginTop: '10px',
              textAlign: 'left',
            }}
          >
            <Title
              level={5}
              style={{
                fontFamily: 'Roboto Slab',
                fontWeight: '400',
                marginTop: '10px',
                textAlign: 'left',
                color: '#28638A',
              }}
            >
              Body
            </Title>
            <ReactQuill
              onBlur={handleTextBlur}
              onChange={handleEmailMessageChange}
              style={{
                fontFamily: 'Roboto Slab',
                fontSize: '16px',
                color: '#28638A',
              }}
              value={emailMessage || ''}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Button
          icon={<RedoOutlined />}
          onClick={() => void handleRegenerate()}
          shape="round"
          type="default"
        >
          Rephrase Body
        </Button>
        <a href={mailtoLink} style={{ marginLeft: 'auto' }}>
          <Button
            icon={<SendOutlined />}
            shape="round"
            style={{ marginLeft: 'auto' }}
            type="primary"
          >
            Open in Email Client
          </Button>
        </a>
        <Button
          icon={<SendOutlined />}
          onClick={() => void handleSend()}
          shape="round"
          style={{ marginLeft: 'auto' }}
          type="primary"
        >
          Send
        </Button>
      </div>
      {task.lead_bio.bio.short_bio &&
        task.lead_bio.bio.short_bio.trim() !== '' && (
          <PushToSendLeadBio shortBio={task.lead_bio.bio.short_bio} />
        )}
    </div>
  );
};

export default PushToSendEmailTask;
