import { Tooltip } from 'antd';

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now.valueOf() - date.valueOf());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  let formattedDateString = '';
  if (diffDays === 1) {
    formattedDateString = 'Yesterday';
  } else if (diffDays <= 7) {
    formattedDateString = `${diffDays} days ago`;
  } else if (diffDays <= 30) {
    formattedDateString = `${Math.ceil(diffDays / 7)} weeks ago`;
  } else {
    formattedDateString = 'on ' + date.toLocaleDateString();
  }
  return (
    <Tooltip title={new Date(dateString).toLocaleString()}>
      {formattedDateString}
    </Tooltip>
  );
}
