import { EditOutlined } from '@ant-design/icons';
import { Alert, Button, Card, List, Modal, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import { useEffect, useState } from 'react';
import { crmLink } from '../../utils';
import { Recording } from '../../types';

export const CollapsableCard = ({
  recording,
  apiUrl,
}: {
  recording: Recording;
  apiUrl: string;
}): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [promptFields, setPromptFields] = useState(
    recording.action_prompt_result ?? {},
  );

  const [role, setRole] = useState<string | undefined>(undefined);
  const [instruction, setInstruction] = useState<string | undefined>(undefined);
  const [communications, setCommunications] = useState<string | undefined>(
    undefined,
  );

  const [evalResponse, setEvalResponse] = useState<string | undefined>(
    JSON.stringify(recording.action_prompt_result ?? {}, null, '\t'),
  );
  useEffect(() => {
    const fetchPrompt = async () => {
      try {
        const url = `${apiUrl}/api/recordings/prompt/${recording.client_id}/${recording.id}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        setRole(jsonData.role);
        setInstruction(jsonData.instruction);
        setCommunications(jsonData.communications);
      } catch (err) {
        console.error('An error occurred while fetching the prompt:', err);
        setRole(undefined);
        setInstruction(undefined);
        setCommunications(undefined);
      }
    };
    fetchPrompt().then().catch(console.log);
  }, []);

  const evalPrompt = async () => {
    try {
      setEvalResponse(undefined);
      const response = await fetch(`${apiUrl}/api/EvaluatePrompt/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          roleInstructions: role,
          prompt: instruction,
          communications: communications,
        }),
      });
      if (!response.ok) {
        throw new Error(`Error evaluating prompt: ${await response.text()}`);
      }
      const results = await response.json();

      setEvalResponse(JSON.stringify(results, null, 2));
    } catch (error) {
      console.error('Error evaluating prompt:', error);
      setEvalResponse(`${error}`);
    }
  };
  const saveResult = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/recordings/${recording.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: evalResponse,
      });
      if (!response.ok) {
        throw new Error(`Error saving field: ${await response.text()}`);
      }
      setPromptFields(JSON.parse(evalResponse ?? '{}'));
    } catch (error) {
      console.error('Error saving field:', error);
      <Alert banner closable message={`${error}`} type="error" />;
    } finally {
      setLoading(false);
    }
  };
  const lead = recording.lead ?? { first_name: 'No', last_name: 'Name' };
  return (
    <Card
      actions={[
        <EditOutlined key="edit" onClick={() => setIsModalVisible(true)} />,
      ]}
      bodyStyle={{ display: show ? 'inline' : 'none' }}
      extra={
        <a
          href={crmLink({
            client_id: recording.client_id,
            source_id: recording.source_lead_id,
          })}
        >
          CRM
        </a>
      }
      style={{ width: '90vw' }}
      title={
        <div onClick={() => setShow(!show)}>
          {lead?.first_name} {lead?.last_name} -{' '}
          {recording.incoming ? 'Inbound' : 'Outbound'} call on{' '}
          {recording.created_at}
        </div>
      }
    >
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card title="Recording" type="inner">
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
            }}
          >
            {recording.recording}
          </Paragraph>
        </Card>
        <Card title="Note" type="inner">
          {recording.note}
        </Card>
        <List>
          {Object.keys(promptFields).map(key => (
            <List.Item key={key} title={key}>
              <List.Item.Meta
                description={JSON.stringify(promptFields[key])}
                title={key}
              />
            </List.Item>
          ))}
        </List>
      </Space>
      <Modal
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        open={isModalVisible}
        title="Prompt Modal"
        width={'90vw'}
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <TextArea
            onChange={e => setInstruction(e.target.value)}
            rows={10}
            value={instruction}
          />
          <TextArea
            onChange={e => setCommunications(e.target.value)}
            rows={5}
            value={communications}
          />
          <Button
            // loading={evalResponse === undefined}
            loading={evalResponse === undefined}
            onClick={() => {
              evalPrompt().then().catch(console.log);
            }}
            type="primary"
          >
            Run Prompt
          </Button>
          <TextArea
            onChange={e => setEvalResponse(e.target.value)}
            rows={15}
            value={evalResponse}
          />
          <Button
            // loading={evalResponse === undefined}
            loading={loading}
            onClick={() => {
              saveResult().then().catch(console.log);
            }}
            type="primary"
          >
            Save response
          </Button>
        </Space>
      </Modal>
    </Card>
  );
};
