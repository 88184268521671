import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PartialActionPromptResult, Recording } from '../../types';
import { CollapsableCard } from './CollapsableCard';
import { Space, Spin, Typography } from 'antd';
import { Appointments } from './Appointments';
import { Coaching } from './Coaching';
import { ActionItems } from './ActionItems';
import { EmailFollowUp } from './EmailFollowUp';
import { AlertBanner } from '../../AlertBanner';
import CopyToClipboardButton from '../../CopyToClipboardButton';

const { Text } = Typography;

const SmallLineBreak = () => (
  <br style={{ lineHeight: 4, content: ' ', fontSize: '5pt' }} />
);

import TagManager from 'react-gtm-module';

export const RecordingPage = ({
  apiUrl,
  isInternal,
}: {
  apiUrl: string;
  isInternal: boolean;
}): JSX.Element => {
  const { clientId, recordingId } = useParams();
  const [recording, setRecording] = useState<Recording | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchRecording = async () => {
      try {
        const url = `${apiUrl}/api/recordings/${clientId}/${recordingId}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        setRecording(jsonData);
      } catch (err) {
        console.error('An error occurred while fetching the lead:', err);
        setRecording(undefined);
      }
    };
    fetchRecording().then().catch(console.log);
  }, []);

  useEffect(() => {
    if (recording != null) {
      const tagManagerArgs = {
        dataLayer: {
          lead_phone_number: recording.lead_phone_number,
          clientId: clientId,
          page: 'recording',
        },
        dataLayerName: 'dataLayer',
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [clientId, recording]);

  const date = new Date(Date.parse(recording?.created_at ?? ''));
  const stringDate = `${date.getMonth() + 1}/${date.getDate()}`;

  const actionPromptResult: PartialActionPromptResult = recording?.action_prompt_result ?? {};

  const shouldEmailFollowUp = actionPromptResult?.should_follow_up_with_email
    && actionPromptResult?.should_follow_up_with_email?.toLowerCase() === 'yes'
    && actionPromptResult?.email && actionPromptResult?.email !== ''
    && actionPromptResult?.email_subject && actionPromptResult?.email_subject != '';

  const shouldTextFollowUp = actionPromptResult?.should_follow_up_with_text?.toLowerCase() === 'yes'
    && actionPromptResult?.text_message
    && actionPromptResult?.text_message !== '';

  return (
    <div style={{fontFamily: 'Roboto'}}>
      <h1 style={{ marginTop: 20 }}>
        {recording && (
          <div>
            {recording.lead?.first_name} {recording.lead?.last_name} - {' '}
            {recording.incoming ? 'Inbound' : 'Outbound'} call on {' '}
            {recording.created_at}
          </div>
        )}
      </h1>
      <AlertBanner error={error} setError={setError} />
      <div>
        <Spin spinning={recording == null}>
          {recording && (
            <Space direction="vertical">
              {isInternal && (
                <>
                  <CollapsableCard
                    apiUrl={apiUrl}
                    recording={recording}
                  />
                  <div>
                    <Text strong>Email: </Text>
                    <Text>{recording?.user_email}</Text>
                    {recording?.user_email ? (
                      <CopyToClipboardButton
                        buttonLocation="agent_email"
                        textToCopy={recording.user_email}
                      />
                    ) : null}
                  </div>
                  <div>
                    <Text strong>Subject: </Text>
                    <Text>
                      Call Summary for {recording?.lead?.first_name}{' '}
                      {recording?.lead?.last_name} - {stringDate}
                      {recording?.user_email ? (
                        <CopyToClipboardButton
                          buttonLocation="subject"
                          textToCopy={`Call Summary for ${recording?.lead?.first_name} ${recording?.lead?.last_name} - ${stringDate}`}
                        />
                      ) : null}
                    </Text>
                  </div>
                </>
              )}

              <div
                style={{
                  textAlign: 'left',
                  background: 'white',
                  fontFamily: 'Arial',
                  fontSize: '11pt',
                  padding: '10px',
                }}
              >
                <Text>Hi {recording?.user_first_name ?? ''},</Text>
                <br />
                <Text>
                  Here is your call summary and our recommendations from your call on {stringDate}.
                </Text>

              </div>
              {actionPromptResult?.summary && <div style={{
                  textAlign: 'left',
                  background: 'white',
                  fontFamily: 'Arial',
                  fontSize: '11pt',
                  padding: '10px',
                }}>
                <Text strong>Call Summary:</Text>
                <SmallLineBreak />
                <Text>{actionPromptResult?.summary}</Text>              
            </div>}

              {(shouldEmailFollowUp || shouldTextFollowUp) && <EmailFollowUp
                emailFollowUp={actionPromptResult?.email ?? ''}
                emailSubjectFollowUp={actionPromptResult?.email_subject ?? ''}
                is_voicemail={
                  !(
                    actionPromptResult?.is_voicemail != null &&
                    actionPromptResult.is_voicemail.toLowerCase() ==
                    'yes'
                  )
                }
                textFollowUp={actionPromptResult?.text_message ?? ''}
              />}

              {(actionPromptResult?.scheduled_appointments?.length ?? 0) > 0
                && <Appointments appointments={actionPromptResult?.scheduled_appointments ?? []} />}

              {(actionPromptResult?.agent_action_items?.length ?? 0) > 0
                && <ActionItems actionItems={actionPromptResult?.agent_action_items ?? []} />}

              {actionPromptResult?.is_initial_call?.toLowerCase() === 'yes'
                && (actionPromptResult?.coaching?.length ?? 0) > 0
                && <Coaching coaching={actionPromptResult?.coaching ?? []} />}
            </Space>
          )}
        </Spin>
      </div>
    </div>
  );
};
