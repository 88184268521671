import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Task } from '../../types';

// Define the shape of an appointment type
interface AppointmentType {
  id: string;
  name: string;
}

// Define the props for the AppointmentTypesDropdown component
interface AppointmentTypesDropdownProps {
  task: Task;
  apiUrl: string;
  clientId: number | undefined;
  onErrorChange: (hasError: boolean) => void;
  onAppointmentTypeModified: (
    taskId: string,
    appointmentTypeName: string,
    appointmentTypeId: string,
  ) => void;
}

const APPOINTMENT_TYPE_NONE = { id: '-1', name: 'None' };

const AppointmentTypesDropdown: React.FC<AppointmentTypesDropdownProps> = ({
  task,
  apiUrl,
  clientId,
  onErrorChange,
  onAppointmentTypeModified,
}) => {
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>(
    [],
  );
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<AppointmentType | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchAppointmentTypes = async (clientId: number) => {
    try {
      const response = await fetch(`${apiUrl}/api/configs`);
      if (!response.ok) {
        throw new Error('Failed to fetch appointment types');
      }
      const data = await response.json();
      console.log('Config data:', data);
      console.log('Client ID:', clientId);

      const clientConfig = data[clientId];
      console.log('Client config:', clientConfig);

      if (clientConfig && clientConfig.appointment_types) {
        setAppointmentTypes([
          ...clientConfig.appointment_types,
          APPOINTMENT_TYPE_NONE,
        ]);
        const selectedType = clientConfig.appointment_types.find(
          (type: AppointmentType) =>
            type.id === task.raw_task?.appointment_type_id,
        );
        setSelectedAppointmentType(selectedType || null);
        if (!selectedType) {
          setIsError(true);
          // console.error('Error fetching appointment types:', error);
          message.error('No appointment types found for the selected client.');
        } else {
          setIsError(false);
        }
      } else {
        setIsError(true);
        message.error('No appointment types found for the selected client.');
      }
    } catch (error) {
      message.error(
        'Failed to load appointment types. Please try again later.',
      );
      console.error('Error fetching appointment types:', error);
      setIsError(true);
    }
  };

  useEffect(() => {
    onErrorChange(isError);
  }, [isError, onErrorChange]);

  useEffect(() => {
    if (clientId !== undefined) {
      fetchAppointmentTypes(clientId).catch(error =>
        console.error('Failed to execute appointmentTypes:', error),
      );
    }
  }, [task.raw_task?.appointment_type_id, clientId]);

  const handleMenuClick = (e: any) => {
    const selected = appointmentTypes.find(type => type.id === e.key);
    setSelectedAppointmentType(selected || null);
    setIsError(false);
    if (selected) {
      onAppointmentTypeModified(task.id, selected.name, selected.id);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {appointmentTypes.map(type => (
        <Menu.Item key={type.id}>{type.name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button
        style={{
          borderColor: isError ? 'red' : 'initial',
          color: isError ? 'red' : 'initial',
          fontWeight: 'bold',
        }}
      >
        {selectedAppointmentType ? selectedAppointmentType.name : 'Select Type'}{' '}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default AppointmentTypesDropdown;
