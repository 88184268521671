import React from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { Button, Divider, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Logo from '../../Logo';
import { AlertBanner } from '../../AlertBanner';

interface DemoAgent {
  firstName: string;
  lastName: string;
  phone: string;
  message: string;
}

export const DemoPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [success, setSuccess] = React.useState<string | undefined>(undefined);
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    message:
      "Hello! This is Alex, your HouseWhisper Digital Assistant. I am reaching out to see if you'd like to add any notes from your recent showing. Please call this number (888) 846-8507, give me the name of the client, and then we can work together to update your CRM. Looking forward to speaking with you!",
  };

  const handleSubmit = async (values: DemoAgent) => {
    try {
      const response = await fetch(`${apiUrl}/api/calls/sms-demo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      if (!response.ok) {
        throw new Error(`Failed to send SMS: ${response.statusText}`);
      }
      const jsonData = await response.json();
      console.log(jsonData);
      setSuccess('SMS sent successfully');
    } catch (err) {
      console.error('An error occurred while sending the SMS:', err);
      setError(`An error occurred while sending the SMS ${err}`);
    }
  };

  const validateForm = (values: DemoAgent) => {
    const errors: Partial<DemoAgent> = {};

    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }

    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (!values.phone) {
      errors.phone = 'Phone is required';
    }

    return errors;
  };

  return (
    <div>
      <AlertBanner error={error} setError={setError} />
      <AlertBanner error={success} setError={setSuccess} type="success" />
      <Logo />
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateForm}
      >
        {({ submitForm, isSubmitting, values, errors, setFieldValue }) => (
          <Form
            labelCol={{ span: 4 }}
            layout="horizontal"
            onFinish={submitForm}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item label="First Name">
              <Input
                onChange={e => setFieldValue('firstName', e.target.value)}
                status={errors.firstName !== undefined ? 'error' : undefined}
                value={values.firstName}
              />
              <ErrorMessage name="firstName" />
            </Form.Item>
            <Form.Item label="Last Name">
              <Input
                onChange={e => setFieldValue('lastName', e.target.value)}
                status={errors.lastName !== undefined ? 'error' : undefined}
                value={values.lastName}
              />
              <ErrorMessage name="lastName" />
            </Form.Item>
            <Form.Item label="Phone">
              <Input
                addonBefore="+1"
                onChange={e => setFieldValue('phone', e.target.value)}
                status={errors.phone !== undefined ? 'error' : undefined}
                value={values.phone}
              />
              <ErrorMessage name="phone" />
            </Form.Item>
            <Form.Item label="Message">
              <Input.TextArea
                onChange={e => setFieldValue('message', e.target.value)}
                value={values.message}
              />
            </Form.Item>

            <Button
              htmlType="submit"
              loading={isSubmitting}
              style={{
                background:
                  'linear-gradient(230.52deg, #16FEBF 0.37%, #338FF0 48.45%, #A328FF 93.73%)',
              }}
            >
              Send
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
