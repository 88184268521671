import { Flex, theme, Typography } from 'antd';
import { Task } from '../../types';
import { CalendarOutlined } from '@ant-design/icons';
const { Text } = Typography;

const getAppointmentTitle = (task: Task) => {
  let title = `${task.title}`;
  if (task.due_date != null) {
    title += ` on ${formatDate(task.due_date)}`;
  }
  return title;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
};

export const TaskTitle = (props: { task: Task }) => {
  const token = theme.useToken();
  const { task } = props;
  const isAppointment = task.task_type === 'appointment';
  const prefix = task.task_type === 'appointment_scheduling' ? 'Confirm: ' : '';
  const title = prefix + (task?.dynamic_title ?? task.title);
  const isCallFollowUp = task.task_type === 'call_follow_up';
  return (
    <>
      <Flex style={{ textAlign: 'left' }}>
        {isAppointment && (
          <div
            style={{
              marginRight: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CalendarOutlined
              style={{ fontSize: '2em', color: token.token.colorPrimary }}
            />
          </div>
        )}
        <Text
          delete={task.checked_off}
          ellipsis={!isAppointment}
          style={{
            fontFamily: 'Roboto Slab',
            // fontSize: isAppointment ? '1.2em' : '1.5em',
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#28638A',
            textDecoration: task.rejected ? 'line-through' : 'none',
          }}
        >
          {title}
        </Text>
      </Flex>
      {!isCallFollowUp && (
        <Text
          delete={task.checked_off}
          style={{
            fontFamily: 'Roboto',
            // fontSize: isAppointment ? '1.2em' : '1.5em',
            fontSize: '12px',
            color: '#28638A',
            textDecoration: task.rejected ? 'line-through' : 'none',
          }}
        >
          {task.due_date && formatDate(task.due_date)}
        </Text>
      )}
    </>
  );
};
